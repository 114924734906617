import { Button, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppInfo } from 'AppState';
import { groupedNavLinks, navLinkCategories } from 'nav/menu';
import Logo from 'logo/Logo';
import ProfileSettings from 'nav/ProfileSettings';

export default function LeftSidebarMainExpandedNew({ handleSidebar, handleHover }) {
  const { dispatchSidebar } = useAppInfo();
  const navigate = useNavigate();
  const location = useLocation();
  const pageName = location.pathname.split('/')[1];

  // Group navLinks by category
  const [mouseEnter,setMouseEnter] = useState(true)
 
  return (
    <>
      <Paper
        // onMouseEnter={()=>setMouseEnter(true)}
        // onMouseLeave={()=>setMouseEnter(false)}
        elevation={1}
        id="expandedMenuMain"
        style={{
          background: 'var(--bgWoodSmoke)',
          position: 'relative',
          width: 'calc(10% + 50px)',
          minWidth: '250px',
          height: '100vh',
          zIndex: 1000,
          overflow: mouseEnter ? 'auto' : 'hidden',
          borderRadius: 0,
          top: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '0 1rem',
            flex: 1,
            position: 'relative',
          }}
        >
          <div
            style={{
              padding: '1rem 0',
              display: 'flex',
              gap: '1rem',
              alignItems: 'center',
              position: 'sticky',
              top: 0,
              zIndex: 10,
              background: 'var(--bgWoodSmoke)',
            }}
          >
            {/* <div style={{display:"flex",alignItems:"center",gap:"0.25rem"}}>
            <Logo color='white' width='30px' height='30px'/>
           
            </div> */}
            <Logo type='long' width='150px' height='30px'/>
            <Button
              sx={{
                marginLeft: 'auto',
                minWidth: '0',
                padding: 0,
                flexDirection: 'column',
                textTransform: 'none',
              }}
              onClick={() => dispatchSidebar({ sidebarLeftExpanded: false })}
            >
              <MenuOpenIcon />
            </Button>
          </div>
          
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', flex: 1 }}>
            {Object.keys(groupedNavLinks).map((category, catIndex) => (
              <div key={catIndex} style={{padding:!["Home","AI"].includes(category) && "1rem 0"}}>
                {/* Category Header */}
                {!["Home","AI"].includes(category) &&
                <Typography
                  style={{
                    fontWeight: 'bold',
                    color: '#ddd',
                    marginBottom: '0.5rem',
                    fontSize: '0.7rem',
                  }}
                >
                  {category}
                </Typography> }

                {/* Links */}
                <div style={{display:"flex",flexDirection:"column",gap:"0.25rem"}}>
                {groupedNavLinks[category].map((option, index) => (
                      <Button
                        key={index}
                        id={`link-${option.name}`}
                        onPointerEnter={() => handleHover(option.name)}
                        onMouseEnter={() => handleHover(option.name)}
                        onClick={() => {
                          handleHover(option.name);
                          if (!option.menu) {
                            handleSidebar(option.link);
                          }
                        }}
                        sx={{
                          display: 'flex',
                          color: pageName === option.link ? 'var(--selectBlue)' : '#ddd',
                          padding: '0.4rem',
                          flex: 1,
                          fontSize: '1.2rem',
                          gap:"1rem",
                          justifyContent: 'flex-start',
                          background: pageName === option.link && '#fafafa',
                          alignItems: 'center',
                          '&:hover': {
                            background: option.link === pageName ? '#F8F8F8' : '#fafafa',
                            color: option.link !== pageName && 'black',
                          },
                        }}
                      >
                        {option?.icon}
                        <Typography
                          style={{
                            // marginLeft: '0.5rem',
                            textAlign: 'left',
                            flex: 1,
                            fontSize: '0.85rem',
                            fontWeight: option.link === pageName && 'bold',
                          }}
                        >
                          {option.name}
                        </Typography>
                        {option?.comment && 
                        <div style={{top:"50%",transform:"translateY(-50%)",position:"absolute",right:"0px",zIndex:"1",borderRadius:"5px",border:"2px solid gray"}}>
                            <Typography style={{fontSize:"0.7rem",color:"gray"}}>Demo</Typography>
                        </div>
                        }
                      </Button>
                ))}
                  </div>
              </div>
            ))}
          
          </div>
          <div
            style={{
              marginTop: 'auto',
              padding: '1rem 0rem',
              width: '100%',
              position: 'sticky',
              bottom: 0,
              zIndex: 1000,
              background: 'var(--bgWoodSmoke)',
            }}
          >
            <ProfileSettings type="long" />
          </div>
        </div>
      </Paper>
    </>
  );
}

// import { Button, Paper, Typography} from '@mui/material';
// import React, { useState } from 'react';
// import MenuOpenIcon from '@mui/icons-material/MenuOpen';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useAppInfo } from 'AppState';
// import { navLinks } from 'nav/menu';
// import Logo from 'logo/Logo';
// import ProfileSettings from 'nav/ProfileSettings';

// export default function LeftSidebarMainExpandedNew({handleSidebar,handleHover}) {

//     const { dispatchSidebar, } = useAppInfo();
//     const navigate = useNavigate();
//     const location = useLocation();
//     const pageName = location.pathname.split('/')[1];
//     const subPage = location.pathname.split('/')[2];

      
//   return (
//     <>
//         <Paper
//           elevation={1}
//           id="expandedMenuMain"
//         //   onMouseLeave={() => !["Home",""].includes(pageName) && dispatchSidebar({"sidebarLeftExpanded":false})}
//           style={{
//             background: 'var(--bgWoodSmoke)',
//             // position:["Home",""].includes(pageName) ?'relative':'absolute',
//             position:"relative",
//             width: 'calc(10% + 50px)',
//             minWidth: '250px',
//             height: '100vh',
//             zIndex: 1000,
//             overflow: 'auto',
//             borderRadius: 0,
//             top:0,
//             display:"flex",flexDirection:"column"
//           }}
//         >
//           <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', padding: '0 1rem'
//             ,flex:1,position:"relative"}}>
//             { 
//             <div style={{padding:"1rem 0",display: 'flex', gap: '1rem', alignItems: 'center',position:"sticky",top:0,zIndex:10,background:"var(--bgWoodSmoke)"}}>
//               <Logo type="long" width="150px" />
//               <Button
//                 sx={{
//                   marginLeft: 'auto',
//                   minWidth: '0',
//                   padding: 0,
//                   flexDirection: 'column',
//                   textTransform: 'none',
//                 }}
//                 onClick={() => dispatchSidebar({"sidebarLeftExpanded":false})}
//               >
//                 <MenuOpenIcon />
//               </Button>
//             </div>}
//             <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",flex:1}}>
//             {navLinks.map((option, index) => (
//               <div
//                 key={index}
//                 style={{
//                   minWidth: '0',
//                   padding: '0',
//                   width: '100%',
//                   boxSizing: 'border-box',
//                   flexDirection: 'column',
//                   justifyContent: 'flex-start',
//                   borderRadius: '4px',
//                   background:pageName===option.link && "#fafafa"
//                 }}
//               >
               
//                 <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
//                   <Button
//                    id={`link-${index}`}
//                     onPointerEnter={() => handleHover(index)}
//                     onMouseEnter={() => handleHover(index)}
//                     onClick={() => {
//                       handleHover(index)
//                       if (!option.menu) {
//                         handleSidebar(option.link);
//                       } 
//                     }}
//                      // '#5C5C5C'
//                     // variant={pageName===option.link && "highlight"}
//                     sx={{
//                       display: 'flex',
//                       color: pageName===option.link? 'var(--selectBlue)':"#fafafa",
//                       padding: '0.4rem',
//                       flex: 1,
//                       fontSize: '0.8rem',
//                       justifyContent: 'flex-start',alignItems:"center",
//                       '&:hover': {background:option.link === pageName ? '#F8F8F8' :"#fafafa",color:option.link !== pageName && 'black'}
//                     }}
//                   >
//                     {option?.icon}
//                     <Typography
//                     style={{ marginLeft: '0.5rem',
//                     textAlign: 'left',flex:1,fontSize:"0.8rem",fontWeight:option.link === pageName && "bold"}}>
//                       {option.name}</Typography>
                    
//                   </Button>
//                 </div>
                
//               </div>
//             ))}
//             </div>
            
//            <div style={{marginTop:"auto",padding:"1rem 0rem",width:"100%"
//             ,position:"sticky",bottom:"0",zIndex:1000,background:"var(--bgWoodSmoke)"}}>
//                 <ProfileSettings type='long'/>
//            </div>

//           </div>

//         </Paper> 
//     </>
//   )
// }
