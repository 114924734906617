import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Material UI Icons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PeopleIcon from '@mui/icons-material/People';
import TodayIcon from '@mui/icons-material/Today';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LanguageIcon from '@mui/icons-material/Language';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TagIcon from '@mui/icons-material/Tag';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const sections = [
  { label: "Clients", icon: <PeopleIcon fontSize="small" />, navigate: "/Clients", color: "#2196F3" }, // Blue
  { label: "Manage Client", icon: <ManageAccountsIcon fontSize="small" />, color: "#9C27B0" }, // Purple
  { label: "Advisory Network", icon: <RecentActorsIcon fontSize="small" />, navigate: "/AskProfessional", color: "#00BCD4" }, // Teal
  { label: "Market & Economy", icon: <LanguageIcon fontSize="small" />, color: "#FF9800" }, // Orange
  { label: "Financial Products", icon: <LocalGroceryStoreIcon fontSize="small" />, navigate: "/FinancialProducts", color: "#4CAF50" }, // Green
  { label: "Reports", icon: <SummarizeIcon fontSize="small" />, navigate: "/Report/TSReport", color: "#F44336" }, // Red
  { label: "Social", icon: <TagIcon fontSize="small" />, navigate: "/Social", color: "#E91E63" }, // Pink
];

const sectionSettings = [
  { label: "Add Client", icon: <AddIcon fontSize="small" />, color: "#FFC107" }, // Amber
  { label: "Events", icon: <TodayIcon fontSize="small" />, color: "#3F51B5" }, // Indigo
  { label: "Settings", icon: <SettingsIcon fontSize="small" />, color: "#607D8B" }, // Blue Grey
];

export default function HomeB2B() {
  const [hoverIndex, setHoverIndex] = useState({ section: -1, setting: -1 });
  const navigate = useNavigate();

  // Common style for cards
  const cardStyle = {
    cursor: "pointer",
    borderRadius: "8px",
    fontWeight: "600",
    fontSize: "16px",
    minWidth: "280px",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    transition: "all 0.3s ease",
    padding: "1rem",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "40px 20px",
        gap: "3rem",
        flexWrap: "wrap",
        background: "#FFFFFF", // White background
      }}
    >
      {/* Left Section */}
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {sections.map((section, index) => (
          <div
            key={index}
            onClick={() => section.navigate && navigate(section.navigate)}
            onMouseEnter={() => setHoverIndex(prev => ({ ...prev, section: index }))}
            onMouseLeave={() => setHoverIndex(prev => ({ ...prev, section: -1 }))}
            style={{
              ...cardStyle,
              backgroundColor: hoverIndex.section === index ? "#f0f8ff" : "#f9f9f9", // Light Blue on hover
              // borderLeft: `5px solid ${section.color}`,
              color: hoverIndex.section === index ? section.color : "#333333",
              transform: hoverIndex.section === index ? "scale(1.02)" : "scale(1)",
            }}
          >
            <div
              style={{
                color:  section?.color,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {section.icon}
            </div>

            <h4 style={{ flex: 1, margin: 0, color: hoverIndex.section === index ? section.color : "#333333" }}>
              {section.label}
            </h4>
            <KeyboardArrowRightIcon style={{ color: hoverIndex.section === index ? section.color : "#757575" }} />
          </div>
        ))}
      </div>

      {/* Right Section */}
      <div
        style={{
          maxWidth: "500px",
          minWidth: "280px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {sectionSettings.map((setting, index) => (
          <div
            key={index}
            onClick={() => setting.navigate && navigate(setting.navigate)}
            onMouseEnter={() => setHoverIndex(prev => ({ ...prev, setting: index }))}
            onMouseLeave={() => setHoverIndex(prev => ({ ...prev, setting: -1 }))}
            style={{
              ...cardStyle,
              backgroundColor:"#f0f8ff", // Light Amber on hover
              borderLeft: `5px solid ${setting.color}`,
              color: hoverIndex.setting === index ? setting.color : "#333333",
              transform: hoverIndex.setting === index ? "scale(1.02)" : "scale(1)",
              padding: "1.5rem",
            }}
          >
            <div
              style={{
                color: hoverIndex.setting === index ? setting.color : "#757575",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {setting.icon}
            </div>
            <h4 style={{ flex: 1, margin: 0, color: "#333333" }}>
              {setting.label}
            </h4>
            {/* <KeyboardArrowRightIcon style={{ color: hoverIndex.setting === index ? setting.color : "#757575" }} /> */}
          </div>
        ))}
      </div>
    </div>
  );
}
