import GoalsSuccessRate from 'myFinancials/Goals/GoalsSuccessRate'
import MyFinancialCardNew from 'myFinancials/MyFinancialCardNew'
import MyFinancialHealthScore from 'myFinancials/FinancialHealth/MyFinancialHealthScore'
import MyFinancialInsightQuestions from 'myFinancials/MyFinancialInsightQuestions'
import ProfileCompletion from 'myFinancials/ProfileCompletion'
import PortfolioFitNotification from 'opportunities/PortfolioFitNotification'
import React, { useState } from 'react'
import TodoNotificationHome from 'myFinancials/Todos/TodoNotificationHome'
import DefaultMessageBox, { DefaultMessageRecommendations } from 'chatGPT/DefaultMessageBox'
import MessageBarBig from 'chatGPT/MessageBarBig'
import TypographyTS from 'components/Text/TypographyTS'
import { useAppInfo } from 'AppState'
import MessageBar from 'chatGPT/MessageBar'
import TextWithBorders from 'components/Text/TextWithBorders'
import FinancialVaultConnect from 'financialVault/FinancialVaultConnect'
import MyAdvisoryTeam from 'finanaceProfessional/MyAdvisoryTeam'
import TabLine from 'components/Tabs/TabLine'
import ProfileLogo from 'components/Logo/ProfileLogo'

export default function HomeInsights() {
  const {stateApp,stateMyFinancials} = useAppInfo()
  const {username} = stateApp
  const {financialView} = stateMyFinancials
  const [tabIndex,setTabIndex] = useState(0)
  
  return (
    <div style={{display:"flex"
    ,flexDirection:"column",alignItems:"center"
    ,gap:"1.5rem",padding:"2rem",boxSizing:"border-box",minHeight:"100%",width:"100%"}}>
      {/* <h3>Hi, {username}</h3>
      <div style={{maxWidth:"1000px"}}>
      <MessageBar dropdownPosition='bottom' sx={{background:"#fafafa",boxShadow:"none",maxWidth:"auto",borderRadius:"10px"}}/>
      </div> */}
      {/* <div style={{display:"flex",gap:"1rem",alignItems:"center",background:"#fafafa"
        ,borderRadius:"25px",padding:"0.5rem",minWidth:"200px"}}>
        <ProfileLogo/>
        {username}

      </div>
      <TabLine tabsSx={{borderBottom:"none"}} variant='noline' 
      tabList={["My Financials","Market Place","Investments","Social","Education","Support","About"]}
      tabIndex={tabIndex}
      handleTabChange={(i)=>setTabIndex(i)}
      /> */}
      {/* <div style={{display:"flex",gap:"1rem"}}>
      <FinancialVaultConnect/>
      <MyAdvisoryTeam/>
      </div> */}
      
     <div style={{maxWidth:"100%",display:"flex",gap:"2rem",justifyContent:"center",flexWrap:"wrap",borderRadius:"10px",padding:"1rem",boxSizing:"border-box"}}>
     <div style={{minWidth:"300px",boxSizing:"border-box",width:"500px",display:"flex",flexDirection:"column",gap:"1rem",borderRadius:"5px"}}>
    
     <MyFinancialCardNew/>
     
     <FinancialVaultConnect/>
      <MyAdvisoryTeam/>
     </div>
     <div style={{padding:"1rem",borderRadius:"10px",display:"flex"
      ,flexDirection:"column",gap:"1rem",height:"max-content",justifyContent:"flex-start"
      ,minWidth:"400px",flex:1,maxWidth:"500px",boxSizing:"border-box"}}>
       
     <TodoNotificationHome/>
     <ProfileCompletion displayType='main'/>
     <MyFinancialHealthScore displayType='home' scoreHeight='60px' scoreWidth='60px'/>
     <GoalsSuccessRate displayType='home' scoreHeight='60px' scoreWidth='60px'/>
     <PortfolioFitNotification/>
     {/* <MyFinancialInsightQuestions/> */}
     {/* <MyAdvisoryTeam/> */}
    
     {/* <AutomateFinancialNotification/> */}
   
     </div>
    
     </div>
   
      {/* <AdditionalStuff/> */}
     </div>
  )
}



export function AdditionalStuff(){
  return (
    <>
        <div style={{maxWidth:"100%",display:"flex",gap:"2rem",justifyContent:"center",flexWrap:"wrap",borderRadius:"10px",padding:"1rem",boxSizing:"border-box"}}>
     <div style={{minWidth:"500px",background:"#fafafa",boxSizing:"border-box"
      ,width:"1000px",padding:"1rem",boxSizing:"border-box"
      ,display:"flex",flexDirection:"column",gap:"1rem",borderRadius:"5px"}}>
     <MyFinancialInsightQuestions/>
     </div>
     </div>

    </>
  )
}