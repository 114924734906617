import React, { useEffect } from 'react'
import { Paper } from '@mui/material'
import HomeFooterBig from './HomeFooterBig'
import InfoBox from 'components/Layout/InfoBox'

export default function HomePageTemplate({pageName="",children}) {

  return (
    <div style={{width:"100%",boxSizing:"border-box",display:"flex",
    flexDirection:"column",alignContent:"center",
    background:"white",minHeight:"100vh",overflowY:"auto"}}>

    <h1
    style={{fontSize:"4rem",padding:"2rem 0",textAlign:"center"}}>{pageName}</h1>
   <div style={{flex:1,width:"100%",display:"flex"
   ,flexDirection:"column",alignContent:"center"
   ,padding:"1rem 4rem"
   ,borderRadius:"5px",boxSizing:"border-box"
   ,position:"relative",overflow:"hidden",marginBottom:"20px"}}>
   <div style={{width:"100%",zIndex:1,display:"flex"
   ,flexDirection:"column",alignContent:"center"}}>
    {children}
    </div>
   </div>
   <HomeFooterBig/>
    </div>
  )
}
