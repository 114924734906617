import TabLine from 'components/Tabs/TabLine'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppInfo } from 'AppState'
import FinancialVaultUploadTab from '../Upload/FinancialVaultUploadTab'
import { ActionAnalytics, ActionInsights, ActionTodos } from './CategoryActions'
import { FinancialVaultBank, FinancialVaultManualEntry } from 'financialVault/FinancialVaultConnections'
import { FinancialVaultManualEntryTable } from 'financialVault/ManualEntry/FinancialVaultManualEntry'

export function FinancialVaultIncome() {
    const [tabIndex,setTabIndex] = useState(0)
    const location = useLocation()
    const navigate = useNavigate()
    const subPage = location.pathname.split('/')[2];
    const subPageName = decodeURIComponent(subPage)
    const {stateMyFinancials} = useAppInfo()
    const {vaultData,financialHealthDetails} = stateMyFinancials
    

  return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",padding:"0.5rem"}}>
        
    <div style={{display:"flex",gap:"1rem",alignItems:"center",paddingBottom:"1rem"}}>
        <ActionAnalytics url={"/MyFinancials/Cashflow"}/>
        <ActionInsights url={"/MyFinancials/Cashflow"}/>
        <ActionTodos url={"/Todos"}/>
    </div>
    <div style={{minHeight:"500px",width:"100%",borderRadius:"25px",background:"#fafafa"
        ,padding:"1rem",boxSizing:"border-box",display:"flex",flexDirection:"column",gap:"0.5rem"}}>
    <TabLine tabsSx={{borderBottom:"none"}} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)} 
    tabList={["Accounts Connected","Manual Entry","Uploaded Documents"]}/>
    <>
    {tabIndex === 0 && 
    <div style={{height:"100px",width:"100%"}}>
    <FinancialVaultBank/> </div>}
    {tabIndex === 1 && <FinancialVaultManualEntryTable category={subPageName}/>}
    {tabIndex === 2 && <FinancialVaultUploadTab/>}
    </>
    </div>
    </div>
  )
}

export function FinancialVaultExpenses() {
    const [tabIndex,setTabIndex] = useState(0)
    const location = useLocation()
    const navigate = useNavigate()
    const subPage = location.pathname.split('/')[2];
    const subPageName = decodeURIComponent(subPage)
    const {stateMyFinancials} = useAppInfo()
    const {vaultData,financialHealthDetails} = stateMyFinancials
    

  return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",padding:"0.5rem",height:"auto"}}>
        
    <div style={{display:"flex",gap:"1rem",alignItems:"center",paddingBottom:"1rem"}}>
        <ActionAnalytics url={"/MyFinancials/Cashflow"}/>
        <ActionInsights url={"/MyFinancials/Cashflow"}/>
        <ActionTodos url={"/Todos"}/>
    </div>
    <div style={{minHeight:"500px",width:"100%",borderRadius:"25px",background:"#fafafa"
        ,padding:"1rem",boxSizing:"border-box",display:"flex",flexDirection:"column",gap:"0.5rem",height:"auto"}}>
    <TabLine tabsSx={{borderBottom:"none"}} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)} 
    tabList={["Accounts Connected","Manual Entry","Uploaded Documents"]}/>
    <>
    {tabIndex === 0 && 
    <div style={{height:"100px",width:"100%"}}>
    <FinancialVaultBank/> </div>}
    {tabIndex === 1 && <FinancialVaultManualEntryTable category={subPageName}/>}
    {tabIndex === 2 && <FinancialVaultUploadTab/>}
    </>
    </div>
    </div>
  )
}


export function FinancialVaultAssets() {
    const [tabIndex,setTabIndex] = useState(0)
    const location = useLocation()
    const navigate = useNavigate()
    const subPage = location.pathname.split('/')[2];
    const subPageName = decodeURIComponent(subPage)
    const {stateMyFinancials} = useAppInfo()
    const {vaultData,financialHealthDetails} = stateMyFinancials
    

  return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",padding:"0.5rem"}}>
        
    <div style={{display:"flex",gap:"1rem",alignItems:"center",paddingBottom:"1rem"}}>
        <ActionAnalytics url={"/MyFinancials/Assets"}/>
        <ActionInsights url={"/MyFinancials/Assets"}/>
        <ActionTodos url={"/Todos"}/>
    </div>
    <div style={{minHeight:"500px",width:"100%",borderRadius:"25px",background:"#fafafa"
        ,padding:"1rem",boxSizing:"border-box",display:"flex",flexDirection:"column",gap:"0.5rem"}}>
    <TabLine tabsSx={{borderBottom:"none"}} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)} 
    tabList={["Accounts Connected","Manual Entry","Uploaded Documents"]}/>
    <>
    {tabIndex === 0 && 
    <div style={{height:"100px",width:"100%"}}>
    <FinancialVaultBank/> </div>}
    {tabIndex === 1 && <FinancialVaultManualEntryTable category={subPageName}/>}
    {tabIndex === 2 && <FinancialVaultUploadTab/>}
    </>
    </div>
    
    </div>
  )
}

export function FinancialVaultLiabilities() {
    const [tabIndex,setTabIndex] = useState(0)
    const location = useLocation()
    const navigate = useNavigate()
    const subPage = location.pathname.split('/')[2];
    const subPageName = decodeURIComponent(subPage)
    const {stateMyFinancials} = useAppInfo()
    const {vaultData,financialHealthDetails} = stateMyFinancials
    

  return (
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",padding:"0.5rem"}}>
        
    <div style={{display:"flex",gap:"1rem",alignItems:"center",paddingBottom:"1rem"}}>
        <ActionAnalytics url={"/MyFinancials/Liabilities"}/>
        <ActionInsights url={"/MyFinancials/Liabilities"}/>
        <ActionTodos url={"/Todos"}/>
    </div>
    <div style={{minHeight:"500px",width:"100%",borderRadius:"25px",background:"#fafafa"
        ,padding:"1rem",boxSizing:"border-box",display:"flex",flexDirection:"column",gap:"0.5rem"}}>
    <TabLine tabsSx={{borderBottom:"none"}} tabIndex={tabIndex} handleTabChange={(i)=>setTabIndex(i)} 
    tabList={["Accounts Connected","Manual Entry","Uploaded Documents"]}/>
    <>
    {tabIndex === 0 && 
    <div style={{height:"100px",width:"100%"}}>
    <FinancialVaultBank/> </div>}
    {tabIndex === 1 && <FinancialVaultManualEntryTable category={subPageName}/>}
    {tabIndex === 2 && <FinancialVaultUploadTab/>}
    </>
    </div>
    </div>
  )
}

