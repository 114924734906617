import { useAppInfo } from 'AppState';
import React, { useState } from 'react';
import FinancialVaultFolders from './FinancialVaultFolders';
import useFindFinancialVault from "data/user/financialVault/useFindFinancialVault";
import FinancialVaultConnections from './FinancialVaultConnections';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, IconButton } from '@mui/material';
import FinancialVaultCategories from './Categories/FinancialVaultCategories';
import FinancialVaultUploadTab from './Upload/FinancialVaultUploadTab';


 
function FinancialVaultNew() {
  const {stateMyFinancials} = useAppInfo()
  const {vaultData,vaultLoaded,vaultCategories,financialView}  = stateMyFinancials
  const location = useLocation()
  const subPage = location.pathname.split('/')[2];
  const subPageName = decodeURIComponent(subPage)
  const navigate = useNavigate()
  useFindFinancialVault()

  // We keep categories in state so we can add new ones


  return (
    <>
    <>
        {/* <div style={{textAlign:"center"}}>
        <h2>Financial Vault</h2>
        <h4>Secured storage to keep all your data safe and automate financial workflows</h4>
        </div> */}            
            
        {!subPage && 
        <div style={{display:"flex",justifyContent:"center"}}>
        <div style={{display:"flex",flexDirection:"column",gap:"1rem",padding:"0.5rem 1rem"}}>
        <div style={{display:"flex",gap:"1rem",flexDirection:"column",padding:"0.5rem 1rem",alignItems:"center",flexWrap:"wrap"}}>
        <div style={{padding:"0.5rem",borderRadius:"5px",boxSizing:"border-box"}}>
            <h3 style={{fontWeight:"normal"}}>Connect and manage your financial data for seamless automation and control</h3>
            </div>
            {/* {financialView !== "personal" && 
          <div style={{padding:"0rem",borderRadius:"5px",boxSizing:"border-box"}}>
          <Alert severity='info'>You are currently exploring the vault of {stateMyFinancials.demoProfile} (demo profile)</Alert>
          </div>} */}
            
        <div style={{display:"flex",gap:"2rem",padding:"0.5rem 1rem",justifyContent:"center",flexWrap:"wrap"}}>
         
        <FinancialVaultFolders mediaItems={vaultData}/>
        
        <FinancialVaultConnections/>
        </div>
        
        </div></div>
        {/* <FinancialVaultUploadTab/> */}
        </div>}
  
        {subPage && <FinancialVaultCategories/>}
      
    </>
    {/* <FinancialVault/> */}
    </>
  );
}

export default FinancialVaultNew;


