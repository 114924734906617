import React from 'react'
import Animation from '../animation/Animation'
import Logo from '../logo/Logo'
import StockMarketLine from '../home/product/StockMarketLine'

export default function PageNotFound() {

  return (
    <div className='appLayout' 
    style={{display:"flex"
    ,flexDirection:"column",justifyContent:"center"
    ,alignItems:"center",height:"100vh",width:"100%",position:"relative",overflow:"hidden"}}>
    <div style={{height:"100%",display:"flex",justifyContent:"center"
    ,alignItems:"center",flexDirection:"column",gap:"1rem"
    ,background:"var(--bgWoodSmoke)",width:"100%",zIndex:"100"}}>
      <div style={{width:"30%"}}>
      <Animation type='404'/>
      </div>
      <h2 style={{color:"white"}}>Page Not Found!</h2>
      {/* <Logo type='long' width='150px' height="25px"/> */}
      <a href='/'><h1 className='gradient_text'>Autonomy.ai</h1></a>
      </div>
    </div>
  )
}