import React, { useState } from 'react';
import MonteCarloSimulation from './MonteCarloSimulation';
import InvestmentProjection from './InvestmentProjection';
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TextWithBorders from 'components/Text/TextWithBorders';
import Income from 'myFinancials/Cashflow/Income';
import LiabilityInfo from 'myFinancials/Liability/LiabilityInfo';
import AssetInfo from 'myFinancials/Asset/AssetInfo';
import GoalInfo from 'myFinancials/Goals/GoalInfo';
import ExpenseInfo from 'myFinancials/Cashflow/ExpenseInfo';
import CashflowInfo from 'myFinancials/Cashflow/CashflowInfo';
import Animation from 'animation/Animation';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const scenariosInfo = [
  {
    category: "Hypothetical Scenarios",
    animation:"Analytics",
    scenarios: [
      { title: "Investment Withdrawal", description: "How much investment can I withdraw? Helpful to see if you can meet your retirement goals.", component: <MonteCarloSimulation /> ,animation:"Analytics"},
      { title: "Income vs Investment", description: "Visualize the power of compounding. Just by investing a percent of your income per month you can accelerate your wealth.", component: <InvestmentProjection />,animation:"Analytics"}
    ]
  },

  {
    category: "Success/Failure Scenarios",
    animation:"Goals",
    scenarios: [
      { title: "Goals Success/Failure", description: 
        "Analyze the success or failure of your financial goals under different scenarios.", 
        component: <GoalInfo info={"SuccessFailure"}/>,
        animation:"Goals"
      },
      { title: "Goals Projection", description: 
        "Project Goals Overtime", 
        component: <GoalInfo info={"Projections"} showDetails/>,animation:"Goals"}
    ]
  },
  {
    category: "Networth",
    animation:"Portfolio",
    scenarios: [
      { title: "Asset Return/Value Projection", description: 
        "Project & Simulate the value/return of Assets overtime.", 
        component: <AssetInfo info={"AssetReturnProjections"}/>, animation:"Portfolio"},
      { title: "Liability Amortization", description: 
          "Get the amount of Interest & Principal to be paid over time", 
          component: <LiabilityInfo info={"Amortization Schedule"}/>, animation:"Home"},    
    ]
  },

  {
    category: "Cashflow",
    animation:"Financials",
    scenarios: [
      { title: "Expense - Inflation Projection", description: 
            "See how the expenses will look like with different inflation for different expenses.", 
            component: <ExpenseInfo info={"Projection"}/> , animation:"Financials"},
      { title: "Income - Growth", description: 
              "Project the growth of your income", 
              component: <Income/>,animation:"Financials"},
      { title: "Net Cashflow Projection", description: 
                "Project Net Cashflow Overtime", 
                component: <CashflowInfo info={"Net Cash Projections"}/>,animation:"Financials"},
     
      
    ]
  },

];

export default function ScenarioInfo() {
  const [page, setPage] = useState(0);
  const [scenarioSelected, setScenarioSelected] = useState(null);

  const handleScenarioClick = (scenario) => {
    setScenarioSelected(scenario);
    setPage(1);
  };
  
  return (
    <>
      {page === 0 ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center" }}>
          {/* <h2 style={{ padding: "2rem" }}>Explore Financial Scenarios</h2> */}
          {scenariosInfo.map((categoryObj, catIdx) => (
            <div key={catIdx} style={{padding:"1rem 2rem",boxSizing:"border-box",display:"flex",gap:"1rem",flexWrap:"wrap"}}>
              <div style={{background:"#fafafa",borderRadius:"10px",padding:"1rem",minWidth:"300px",maxWidth:"400px",minHeight:"200px",display: "flex",flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
              <h4 style={{ fontWeight: "bold"}}>{categoryObj.category}</h4>
              <div style={{width:"150px"}}>
              <Animation loop={false} type={categoryObj.animation}/>
              </div>
              </div>
              
              {/* <TextWithBorders text={categoryObj.category}/> */}
              <div style={{ display: "flex",flexDirection:"column", gap: "1rem",cursor: "pointer"}}>
                {categoryObj.scenarios.map((scenario, idx) => (
                  <div key={idx} 
                  className='card-hover'
                  onClick={() => handleScenarioClick(scenario)}
                    style={{display:"flex",gap:"1rem",alignItems:"center"
                    ,padding: "1rem", background: "#fafafa", 
                    borderRadius: "5px", width: "400px",
                    position:"relative",boxSizing:"border-box"}}>
                    <div style={{display:"flex",flexDirection:"column",gap:"0.2rem",flex:1}}>
                    <h4 style={{ fontWeight: "normal" }}>{scenario.title}</h4>
                    <h5>{scenario.description}</h5>
                    </div>
                    <KeyboardArrowRightIcon sx={{color:"gray"}}/>
                    {/* {scenario?.animation && <div style={{width:"150px",marginTop:"auto"}}><Animation loop={false} type={scenario.animation}/></div>} */}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div style={{ padding: "1rem" }}>
          <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
          <IconButton onClick={() => setPage(0)}><ArrowBackIosIcon/></IconButton>
          <h3>Scenario / {scenarioSelected?.title}</h3>
            </div>

          {scenarioSelected?.component}
        </div>
      )}
    </>
  );
}


// function PageCategories(){
//   return ( <div style={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center" }}>
//     {/* <h2 style={{ padding: "2rem" }}>Explore Financial Scenarios</h2> */}
//     {scenariosInfo.map((categoryObj, catIdx) => (
//       <div key={catIdx} style={{ width: "80%",maxWidth:"1000px",padding:"1rem 2rem",boxSizing:"border-box"}}>
//         <h4 style={{ fontWeight: "bold"}}>{categoryObj.category}</h4>
//         {/* <TextWithBorders text={categoryObj.category}/> */}
//         <div style={{ display: "flex", gap: "2rem", flexWrap: "wrap", cursor: "pointer",marginTop:"1rem"}}>
//           {categoryObj.scenarios.map((scenario, idx) => (
//             <div key={idx} 
//             className='card-hover'
//             onClick={() => handleScenarioClick(scenario)}
//               style={{display:"flex", flexDirection: "column", alignItems: "center"
//               ,padding: "1rem", background: "#fafafa", 
//               borderRadius: "5px", width: "300px",boxShadow:"0 2px 5px rgba(0, 0, 0, 0.1)",
//               position:"relative",boxSizing:"border-box"}}>
//               <h3 style={{ fontWeight: "bold" }}>{scenario.title}</h3>
//               <h5 style={{paddingTop:"0.25rem"}}>{scenario.description}</h5>
//               {scenario?.animation && <div style={{width:"150px",marginTop:"auto"}}><Animation loop={false} type={scenario.animation}/></div>}
//             </div>
//           ))}
//         </div>
//       </div>
//     ))}
//   </div>)
// }