import React, { useEffect, useState } from 'react'
import DefaultMessageBox from 'chatGPT/DefaultMessageBox'
import MessageBarBig from 'chatGPT/MessageBarBig'
import MessageTape from 'chatGPT/MessageTape'
import TypographyTS from 'components/Text/TypographyTS'
import HomeBackground from './HomeBackground'
import useHandleLogin from 'auth/useHandleLogin'
import { Button } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
export default function HomePageChatLanding() { 

  const {handleLogin} = useHandleLogin()
  const navigate = useNavigate()
  return (
    <>
    <HomeBackground/>
   <div style={{ display: "flex", flexDirection: "column", 
    boxSizing: "border-box",position:"relative", position: "relative",background:"white"
    ,minHeight:"calc(100vh - 50px)"
    }}>

    
  <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column", 
    padding: "1rem", gap: "2rem",
     boxSizing: "border-box",paddingTop:"200px",zIndex:3,flex:1}}>
      
    <div>
    <h1 className="fade-in" style={{fontSize:"2rem", fontWeight: "600", margin: 0, padding: 0,textAlign:"center", fontFamily:"'Montserrat', sans-serif"}}>
    Achieve Financial Autonomy with AI
    </h1>
   </div>
    {/* <h2>Your Money. Your Choices. Your Financial Autonomy.</h2> */}
    <div style={{ width: "50%", maxWidth: "800px", minWidth: "400px" }}>
      <MessageBarBig />
    </div>
    <DefaultMessageBox messages={[{title:"Automate Your Budgeting"},{title:"Automate Your Savings"},{title:"Automate Your Investments"},{title:"Automate Your Financial Applications"}]}  explore={false}/>
   
    {/* <Button variant="outlined" onClick={()=>navigate("/HowItWorks")} style={{height:"max-content",zIndex:"3",borderRadius:"50px",background:"var(--bgWoodSmoke)",color:"white"}}>3 Steps to Acheive Financial Autonomy <KeyboardArrowRightIcon/></Button> */}
  </div>
  
{/*   
  <div id='messageTape' style={{width: "100%",padding:"2rem 0",boxSizing:"border-box",position:"relative",zIndex:3}}>
    <MessageTape tickerTape={true}/>
  </div> */}
</div>
  </>
  )
}


