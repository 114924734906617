import { useEffect, useState } from "react";
import { dfin } from "content/constants";
import useRealTimePriceMultiple from "data/eod/useRealTimePriceMultiple";
import getPerformanceDetails from "../utils/getPortfolioDetails";
import { useAppInfo } from "AppState";

export default function usePortfolioSummaryDetails(){

const {statePortfolio,dispatchPortfolio} = useAppInfo()
const {tickerSelectedCode,tickerCodeMap,portfolioSel} = statePortfolio

const {prices} = useRealTimePriceMultiple(tickerSelectedCode)



useEffect(() => {
  if (statePortfolio.portfolioSel.length > 0) {
    // Extract unique tickers and codes
    const uniqueTickers = new Set();
    const uniqueCodes = new Set();
    const tickerToCodeMap = {};

    statePortfolio.portfolioSel.forEach(obj => {
      const ticker = obj[dfin.ticker];
      const code = obj["CODE"];

      uniqueTickers.add(ticker);
      uniqueCodes.add(code);
      tickerToCodeMap[ticker] = code; // Map each ticker to its code
    });

    // Dispatch the unique tickers and codes to the portfolio context
    dispatchPortfolio({
      tickerSelected: Array.from(uniqueTickers),
      tickerSelectedCode: Array.from(uniqueCodes),
      tickerCodeMap: tickerToCodeMap
    });
  }
  else{
    dispatchPortfolio({
      tickerSelected: [],
      tickerSelectedCode: [],
      tickerCodeMap: {}
    });
  }
}, [statePortfolio.portfolioSel]);
useEffect(() => {
  if (statePortfolio.portfolioSel.length>0 && tickerSelectedCode.length>0 && tickerCodeMap){
  const {holdingDetails,portfolioOverview,portfolioTransactionTable} = getPerformanceDetails({portfolioSel,tickerCode:tickerSelectedCode,tickerCodeDict:tickerCodeMap,prices})

  dispatchPortfolio({
    portfolioOverview: portfolioOverview,
    portfolioTable: holdingDetails,
    portfolioTransactionTable: portfolioTransactionTable
  });
}
else{
  dispatchPortfolio({
    portfolioOverview: {},
    portfolioTable: [],
    portfolioTransactionTable: []
  });
}

}, [statePortfolio.portfolioSel, prices]);

}

