import React, { useState } from "react";
import TabGeneral from "components/Tabs/TabGeneral";
import HomePageTemplate from "../../home/HomePageTemplate";
import TextWithBorders from "components/Text/TextWithBorders";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconButton } from "@mui/material";
import { firebaseMedia } from "media/firebaseMedia";

export default function About() {
  const [tabIndex, setTabIndex] = useState(0);

  function handleTabChange(index) {
    setTabIndex(index);
  }
  return (
    <HomePageTemplate >
      
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          padding:"1rem",paddingBottom:"3rem",display:"flex",flexDirection:"column",gap:"4rem"
        }}
      >
             
          
          <div style={{display:"flex",position:"relative",height:"max-content",width:"100%"}}>
          <div>
          <h1 className="fade-in" style={{padding:"1rem",maxWidth:"700px",fontSize:"3rem"}}>
            Empowering you to acheive financial autonomy</h1>
          
          {/* <TextWithBorders text={"Who are we?"} sx={{fontSize:"1.3rem"}}/> */}
          <h3 className="fade-in"  style={{padding:"1rem",maxWidth:"600px",fontWeight:"normal"}}>
{/*             
            Autonomy is a personal finance and financial market analytics company. We leverage AI to assess the impact of every market and economic development on personal finances, providing timely alerts, expert guidance, and identifying new opportunities. */}
            
            We are a financial empowerment platform that uses AI to automate financial management and deliver personalized financial insights. 
            
            <br/><br/>
            We are not here to grow our assets under management or encourage more trading.<br/> <br/>
            Our mission is simple - to provide you with high-quality insights and guidance, empowering you to confidently take stock of your finances and make smarter decisions. 
            </h3>
            </div>
            <div style={{flex:1}}> 
     
        <img 
        style={{height:"100%",width:"100%",objectFit:"contain"}}
        src={firebaseMedia?.landing_about}>
        
        </img>
       
      </div>
            
          </div>
          <div>
          <TextWithBorders text={"Team"} sx={{fontSize:"1.3rem"}}/>
          <div style={{display:"flex",gap:"3rem",flexWrap:"wrap",padding:"2rem 1rem"}}>
            {/* <h1  style={{fontSize:"3rem"}}>Team</h1> */}
            {teamInfo.map((team) => (
              <TeamCard key={team.name} {...team} />
            ))}

          </div>
          </div>
 
          </div>
    </HomePageTemplate>
  );
}

function TeamCard({ name, img,role,description,linkedIn}) {
  return (
    <div style={{ display: "flex", gap: "1rem",minWidth:"400px",maxWidth:"600px"}}>
    <div style={{display:"flex",flexDirection:"column",gap:"0.5rem",alignItems:"center"}}>
      <img
        src={img}
        alt="profile"
        style={{
          height: "100px",
          width: "100px",
          objectFit: "cover",
          borderRadius: "50%",
        }}
      />
      
    </div>
    <div>
      <h2 style={{ fontWeight: "bold"}}>{`${name}`}</h2>
      <h4 style={{fontWeight:"bold"}}>{role}</h4>
      <IconButton style={{padding:0}}>
      <LinkedInIcon fontSize="small" onClick={() => window.open(linkedIn, "_blank")}/>
      </IconButton>
      <h4 style={{marginTop:0,padding:0}}> 
      <ReactMarkdown rehypePlugins={rehypeRaw}>   
        {description}
      </ReactMarkdown>
      </h4>
    </div>
  </div>
  )
}

const teamInfo = [
  
  {name:"Milind Mehere",role:"Founder, Chairman & CEO",
    description:`Milind Mehere is Yieldstreet’s Founder, as well as a Board Member and Senior Advisor. He previously served as Chief Executive Officer of the leading private market investing platform from 2015 to July 2023.
Milind is an award-winning entrepreneur with a track record of building large, scalable businesses and creating new product categories. <br /><br /> Previously, Milind Co-founded and scaled Yodle — an ad-tech platform for SMBs — to over $200 million in revenue and 1,400 employees. Yodle was ranked four times in the Inc. 500 list and was acquired by Web.com for $342 million in 2016. Milind was named an Innovation Fellow at Columbia University’s Lang Center, and is an international keynote speaker, having spoken at Forbes, Bloomberg, Cheddar, LendIt, Citi Bank, Goldman, Columbia, and Harvard, among others.`,
img:firebaseMedia?.profile_milind,
linkedIn:"https://www.linkedin.com/in/milindmehere/"},

  {name:"Yash Shah",role:"Founder & CPO",
  description:`Yash is the visionary mind behind Autonomy, dedicated to empowering individuals to take control of their financial future. With over a decade of expertise in accounting and equity markets, he has combined his passion for finance and technology to develop AI-driven tools that simplify personal finance and provide clear, actionable insights. <br/> <br/>
  Beyond his expertise in finance and technology, Yash has also pursued a career in mountaineering in the Himalayas. An avid nature enthusiast, he is deeply committed to sustainability, striving to make a positive impact on both the environment and the lives of those around him.
  `,
        img:firebaseMedia?.profile_yash,linkedIn:"https://www.linkedin.com/in/ys136/"},

  {name:"Louis Gagnon",role:"Founder & Advisor",
    description:`Over 30 years, Louis and his teams helped generate billions of dollars in corporate revenues while positively impacting more than 60 million people on 6 continents.

Social impact of Louis and teams throughout his career:
* 58M people finding jobs online in NA, SA, EU, AU, Asia
* 50K US SMBs boosted marketing effectiveness in NA
* 100M books consumed through their ears in NA, EU, AU, Asia
* 1M users self-monitored and self-cared for their mental health in NA, AU
* 1.1M celebrated Unity through culture at the National Mall in DC (1.5B online) -$30M economic impact
* Contributing to build the most influential French University in the world (Université de Montréal)`,
img:firebaseMedia?.profile_louis,
linkedIn:"https://www.linkedin.com/in/louisgagnon/"
  }
      ]
