import React from 'react';
import { useNavigate } from 'react-router-dom';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EventIcon from '@mui/icons-material/Event';
import { Button } from '@mui/material';
import ThreePIcon from '@mui/icons-material/ThreeP';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const commonButtonStyles = {
  borderRadius: "25px",
  boxShadow:"none",
  width: "max-content",
  // minHeight: "50px",
  minWidth: "200px",
  textTransform: "none", // Prevent uppercase text transformation
  fontWeight: 600,
  padding:"0.5rem",
  transition: "background-color 0.3s",
};

export default function CategoryActions() {
  return <div>CategoryActions</div>;
}

export function ActionAnalytics({ url }) {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<AnalyticsIcon sx={{ color: "#42a5f5", fontSize: 28 }} />}
      // variant="contained"
      onClick={() => url && navigate(url)}
      sx={{
        ...commonButtonStyles,
        backgroundColor: "#e3f2fd", // Light blue background
        color: "#0d47a1",            // Darker blue text
        '&:hover': {
          backgroundColor: "#bbdefb",
        },
      }}
    >
      Analytics
    </Button>
  );
}

export function ActionFinancialProducts({ url="/FinancialProducts" }) {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<ShoppingCartIcon sx={{ color: "#42a5f5", fontSize: 28 }} />}
      // variant="contained"
      onClick={() => url && navigate(url)}
      sx={{
        ...commonButtonStyles,
        backgroundColor: "#e3f2fd", // Light blue background
        color: "#0d47a1",            // Darker blue text
        '&:hover': {
          backgroundColor: "#bbdefb",
        },
      }}
    >
      Financial Products
    </Button>
  );
}

export function ActionProfessionalAdvice({ url='/AskProfessional' }) {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<ThreePIcon  sx={{ color:"#1b5e20", fontSize: 28 }} />}
      // variant="contained"
      onClick={() => url && navigate(url)}
      sx={{
        ...commonButtonStyles,
        backgroundColor: "#e8f5e9", // Light green background
        color: "#1b5e20",           // Darker green text
        '&:hover': {
          backgroundColor: "#c8e6c9",
        },
      }}
    >
      Professional Advice
    </Button>
  );
}


export function ActionInsights({ url }) {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<AutoAwesomeIcon sx={{ color: "#66bb6a", fontSize: 28 }} />}
      // variant="contained"
      onClick={() => url && navigate(url)}
      sx={{
        ...commonButtonStyles,
        backgroundColor: "#e8f5e9", // Light green background
        color: "#1b5e20",           // Darker green text
        '&:hover': {
          backgroundColor: "#c8e6c9",
        },
      }}
    >
      Insights
    </Button>
  );
}

export function ActionTodos({ url }) {
  const navigate = useNavigate();
  return (
    <Button
      startIcon={<EventIcon sx={{ color: "#8e24aa", fontSize: 28 }} />}
      // variant="contained"
      onClick={() => url && navigate(url)}
      sx={{
        ...commonButtonStyles,
        backgroundColor: "#f3e5f5", // Light purple background
        color: "#4a148c",           // Darker purple text
        '&:hover': {
          backgroundColor: "#e1bee7",
        },
      }}
    >
     AI Todos
    </Button>
  );
}
