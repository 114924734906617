import HomeFooterBig from 'home/HomeFooterBig'
import NavLanding from 'nav/NavLanding'
import React from 'react'
import { Outlet } from 'react-router-dom'

export default function AppLayoutLanding() {
  return (
    <>
    <NavLanding/>

    <div className='appLandingContentContainer'>
          <Outlet/>
    </div>
    </>
  )
}