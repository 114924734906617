import React from "react";
import "./LogoSVG.css"; // CSS file for animations

export default function LogoLoader({ height = 150, width = 120, color = "black" }) {
  return (
    <div className="logo-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 375 375"
        height={height}
        width={width}
        preserveAspectRatio="xMidYMid meet"
        className="pulsating-logo"
      >
        <defs>
          <clipPath id="clip1">
            <path d="M 18.699219 277 L 262 277 L 262 339 L 18.699219 339 Z" />
          </clipPath>
          <clipPath id="clip2">
            <path d="M 202 120 L 356.199219 120 L 356.199219 363.785156 L 202 363.785156 Z" />
          </clipPath>
          <clipPath id="clip3">
            <path d="M 80 11.285156 L 246 11.285156 L 246 268 L 80 268 Z" />
          </clipPath>
        </defs>
        <g clipPath="url(#clip1)">
          <path
            fill={color}
            d="M 49.242188 277.503906 L 232.078125 277.503906 L 261.03125 338.199219 L 18.769531 338.199219 L 49.242188 277.503906"
          />
        </g>
        <g clipPath="url(#clip2)">
          <path
            fill={color}
            d="M 332.640625 311.992188 L 356.261719 363.71875 L 284.648438 363.71875 L 202.371094 190.601562 L 238.175781 120.25 L 332.640625 311.992188"
          />
        </g>
        <g clipPath="url(#clip3)">
          <path
            fill={color}
            d="M 165.039062 241.640625 L 152.851562 267.160156 L 80.476562 267.160156 L 210.75 11.28125 L 245.796875 83.007812 L 165.039062 241.640625"
          />
        </g>
      </svg>
    </div>
  );
}
