
import React from 'react'
import Modal from 'components/Modal/Modal'
import Logo from '../../logo/Logo'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import { useNavigate } from 'react-router-dom';
import { useAppInfo } from 'AppState';
import { Alert } from '@mui/material';

export default function ContactModal() {
const {dispatchHome,stateHome} = useAppInfo()
const navigate = useNavigate()
  function handleModal(){
    dispatchHome({"contactUs":false})
    navigate("/")
  }
  function handleHome(){
    dispatchHome({"contactUs":false})
    navigate("/")
  }
  return (
    <>
    {stateHome.contactUs && 
    <Modal onClose={handleModal} closeIcon={false} width="65%" height='auto'>
  <div style={{display:"flex"}}>
<div style={{width:"30%",padding:"1rem",background:"#f7f7f7",borderRadius:"5px"}}>
  
  <Logo color='black' height='30px' width='150px' type='long'></Logo>
  <div >
  <h4 style={{ margin: "1rem 0 0 0",padding:0 }}>We are a comprehensive financial market system, offering real-time analytics and insights.</h4>
  <h4 style={{  margin: "1rem 0 0 0",padding:0 }}>Track and analyze global markets, gain valuable insights, and embark on a journey of learning and application.</h4>
<h4 style={{ margin: "1rem 0 0 0",padding:0 }}>Our mission at Autonomy is to empower our users. Equipped with the right tools, frameworks, and insights, you can confidently navigate your financial path and shape your economic future.</h4>
  </div>
  {/* <button className='btn' style={{marginTop:"1rem"}} onClick={handleHome}>Home Page</button> */}
  <SocialMediaHandles/>
  </div>


<div style={{
  padding:"0 2rem",
  width: "70%",
}}>

  <h2>Hey there,</h2>
  <h3 style={{fontWeight:"normal"}}>Thank you for your interest.</h3>
  
  <h3 style={{margin:"1rem 0 0 0",padding:0,fontWeight:"normal"}}>We apologize for the inconvenience. Our platform is currently undergoing active development and is exclusively accessible to our Beta Users at the moment.</h3>
  <h3 style={{margin:"1rem 0 0 0",padding:0,fontWeight:"normal"}}>If you're curious about our offerings or interested in joining our Beta program, we'd love to connect with you!</h3>
  
  <h3 style={{fontWeight:"bold",marginTop:"1rem",fontWeight:"normal"}}>Feel free to contact us via email, phone, or on our social media channels.</h3>
  
  <div style={{display:"flex",gap:"2rem",marginTop:"1rem",flexDirection:"column"}}>
  <div style={{display:"flex",gap:"2rem"}}>
  <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
  <a
    href="mailto:yash@autonomyfinance.com?subject=Inquiry&body=Hello%20there"
    style={{
      color: "black"
    }}
  >
    <EmailIcon fontSize="large" />
  </a>
  <h3 style={{color:"black",fontWeight:"normal"}}>yash@autonomyfinance.com</h3>
  </div>

  {/* <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
  <a
    href="tel:+17782234263"
    style={{
      fontSize: "2rem",
      color: "black"
    }}
  >
    <PhoneIcon fontSize="large" />
  </a>
  <h3 style={{color:"black"}}>+1 778-223-4263</h3>
  </div> */}
  </div>

  </div>
</div>
</div>
    </Modal>}
    </>
  )
}


function SocialMediaHandles(){
  return (
    <div style={{display:"flex",gap:"2rem",alignItems:"center",padding:"1rem 0"}}>
    <a
      href="https://x.com/AutonomyFinance"
      target='blank'
      style={{
        fontSize: "2rem",
        color: "black",
        display:"flex",
        gap:"1rem"
      }}
    >
       <XIcon style={{fontSize:"2rem"}} />
       
    </a>
    <a
      href="https://www.instagram.com/autonomyfinance/"
      target='blank'
      style={{
        fontSize: "2rem",
        color: "black",
        display:"flex",
        gap:"1rem"
      }}
    >
      <InstagramIcon style={{fontSize:"2rem"}} />
    </a>
    <a
      href="https://www.instagram.com/autonomyfinance/"
      target='blank'
      style={{
        fontSize: "2rem",
        color: "black",
        display:"flex",
        gap:"1rem"
      }}
    >
       <YouTubeIcon style={{color:"red",fontSize:"3rem"}}  />
       
    </a>
    </div>
  )
}