import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Snackbar, Typography, Card, Paper } from '@mui/material';
import { useAppInfo } from 'AppState';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ModalOnboarding from 'components/Modal/ModalOnboarding';
import { createPortal } from 'react-dom';
import ProfileLogo from 'components/Logo/ProfileLogo';
import useUpdateOnboarding from 'data/user/onBoarding/useUpdateOnboarding';

// We’re different from other financial systems in some really special ways, so we’d like to take you through them.

function UserTour() {
  const { stateApp} = useAppInfo();
  const {username,onboarding} = stateApp;
  const UpdateOnboarding = useUpdateOnboarding()
  const [startTour, setStartTour] = useState(true);
  const [tourOngoing, setTourOngoing] = useState(false);
  const [step, setStep] = useState(1);
  const [targetRef, setTargetRef] = useState("nav-links");
  const [stepContentSelected, setStepContentSelected] = useState();
  const [showCelebration, setShowCelebration] = useState(false);
  const navigate = useNavigate();

  function handleOnBoardingComplete(){
    setStartTour(false)
    const updatedOnboarding = {...onboarding,showAround:true}
    UpdateOnboarding(username,updatedOnboarding);
  }

  useEffect(() => {
    const stepSelected = stepContent[step - 1];
    setStepContentSelected(stepSelected);
    setTargetRef(stepSelected?.elementId);

    if (step > stepContent.length) {
      setShowCelebration(true);
      setTimeout(() => setShowCelebration(false), 3000);  // 3000 ms for fade in and fade out
      setTourOngoing(false);  // Reset step or close the tour as needed
      setTimeout(() => handleOnBoardingComplete(), 6000);
    }
  }, [step]);


  return (
    <>
    {startTour && createPortal(
      <Paper 
  elevation={9}
  style={{
    position: "absolute",
    top: "50%",  // Center vertically
    left: "50%", // Center horizontally
    transform: "translate(-50%, -50%)", // Adjust the position to the exact center
    width: "300px",
    minHeight: "300px",
    background: "var(--bgWoodSmoke)",
    padding: "1rem",
    zIndex: "10000",
    display: "flex",
    flexDirection: "column",
    gap: "1rem"
  }}
>
        <div style={{display:"flex",gap:"0.25rem",alignItems:"center"}}>
          {/* <ProfileLogo/>  */}
          <h2 style={{fontWeight:"bold",color:"white"}}>{username},</h2>
          <IconButton
          aria-label="close"
          onClick={handleOnBoardingComplete}
          sx={{
            marginLeft:"auto",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
          </div>
       
       
        <div style={{flex:1}}>
          <Typography variant='h5' style={{color:"white"}}>We’re different from other financial systems in some really special ways, so we’d like to show you around.</Typography>
        </div>
        <div style={{display:"flex",justifyContent:"center"}}>
          <Button variant="contained" onClick={() => { setTourOngoing(true);setStartTour(false)}} disabled={tourOngoing}>
            Show me around
          </Button>
        </div>
      </Paper>,document.body)}

      {tourOngoing && (
        <ModalOnboarding onClose={() =>{setTourOngoing(false);handleOnBoardingComplete()}} targetRef={targetRef}>
          <div style={{ display: "flex", flexDirection: "column", 
          justifyContent: "center", gap: "0.5rem"}}>
            <h2 style={{color:"white"}}>{stepContentSelected?.name}</h2>
            {stepContentSelected && stepContentSelected?.description.map((obj, index) => (
              <Typography sx={{color:"white"}} key={index}>{obj}</Typography>
            ))}
            <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
              {step>1 && <Button  onClick={() => setStep(step - 1)}>Back</Button>}
              <Button variant='contained' onClick={() => setStep(step + 1)}>Next</Button>
              
              <div style={{ marginLeft: "auto",color:"white"}}>{step}/{stepContent.length}</div>
            </div>
          </div>
        </ModalOnboarding>
      )}

      {showCelebration && 
      createPortal(<div
        
        style={{
          zIndex:100000,
          background:"rgb(50, 50, 50)",
          padding:"1rem",
          borderRadius:"4px",
          position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",
          '@keyframes fadeEffect': {
            '0%': { opacity: 0 },
            '50%': { opacity: 1 },
            '100%': { opacity: 0 }
          }
        }}
      >
        <h1 style={{color:"white"}}>You are all set!</h1>
        </div>,document.body)
        }
    </>
  );
}

export default UserTour;


const stepContent = [
    
  {
    step: 1,
    name: "Autonomy Assistant",
    description: [
      "Ask AI questions about your finances, opportunities, markets, economy, etc"
    ],
    elementId: "assistant"
  },
  
  {
    step: 2,
    name: "Navigation",
    description: [
      "Navigate your financial journey with 4 key categories: Personal, Community, Market, and Reports & Education."
    ],
    elementId: "leftSidebarMain"
  },
  // {
  //   step: 3,
  //   name: "Search",
  //   description: [
  //     "Find Tickers, Economy data, Industry/Sector info, and Resources."
  //   ],
  //   elementId: "searchButton"
  // },
  
  {
    step: 3,
    name: "Search, Watchlist, Chats & More...",
    description: [
      "Search for tickers and resources, create personalized watchlists, take notes, and collaborate with your network through chats"
    ],
    elementId: "rightSidebar"
  },

  // {
  //   step: 5,
  //   name: "View Screen",
  //   description: [
  //     "You can view dashboard, chats with AI, reports,etc" 
  //   ],
  //   elementId: "contentWrapper"
  // },
 
  {
    step: 4,
    name: "Profile",
    description: [
      "This is where you can find your Account Settings, Billing Info, Support,etc"
    ],
    elementId: "userProfile"
  },
  {
    step: 5,
    name: "Help",
    description: [
      "Need help? Find assistance here!"
    ],
    elementId: "help"
  }
];

 // {
  //   step: 6,
  //   name: "Profile",
  //   description: [
  //     "This is where you can find your Account Settings, Billing Info, Support,etc" 
  //   ],
  //   elementId: "userProfile"
  // },
  
// {
  //   step: 5,
  //   name: "My Financials Page",
  //   description: [
  //     "Explore your Financials and discover common themes across objectives."
  //   ],
  //   elementId: "link-0"
  // },
  // {
  //   step: 6,
  //   name: "Page Menu",
  //   description: [
  //     "Access the page menu here."
  //   ],
  //   elementId: "leftSidebar"
  // },