

import { openaiAPI } from 'appState/initialStateAssistant';
import React, { useEffect, useState } from 'react'

export async function fetchAIVision({image_url,message="Summarise this image"}) {
    const response = await openaiAPI.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "user",
            content: [
              { type: "text", text: message },
              {
                type: "image_url",
                image_url: {
                  "url": image_url,
                  "detail": "high"
                },
              },
            ],
          },
        ],
      });
      return response.choices[0];
}
