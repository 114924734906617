import React, { useEffect, useState } from 'react';
import SearchNav from './SearchNav';
import { Button, IconButton, Typography} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { navLinkCategories} from './menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppInfo } from 'AppState';
import HoverMenu from 'sidebar/LeftSidebar/HoverMenu';
import Logo from 'logo/Logo';
import ChatAIMenu from 'chatGPT/ChatAIMenu';
import PageHeaderIcons from './PageHeaderIcons';
import AlertButton from 'myFinancials/AlertButton';
import Toggle from 'components/Inputs/Toggle';
import DemoToggle from 'myFinancials/Demo/DemoToggle';
import ProfileCompletion from 'myFinancials/ProfileCompletion';
import GoalsSuccessRate from 'myFinancials/Goals/GoalsSuccessRate';
import MyFinancialHealthScore from 'myFinancials/FinancialHealth/MyFinancialHealthScore';
import { CurrencySettingsButton } from 'myFinancials/Template/MyFinancialsHeaderTemplate';
import ActiveTickerButton from 'analytics/ticker/ActiveTickerButton';
import AddTodo from 'myFinancials/Todos/TodoCRUD';
import { ArrowBackIos } from '@mui/icons-material';

export default function NavNew() {
  const { dispatchSidebar, stateSidebar,stateMyFinancials,dispatchMyFinancials} = useAppInfo();
  const {financialView} = stateMyFinancials
  const {sidebarLeftExpanded} = stateSidebar
  const navigate = useNavigate();
    const location = useLocation();
    const pageName = location.pathname.split('/')[1];
    const subPage = location.pathname.split('/')[2];
    const currentPage = navLinkCategories.find((page) => page.link === pageName);
    const currentSubPage = currentPage?.menu?.find((sub) => sub.link === subPage);
    const menu = currentPage?.menu ? currentPage?.menu.map(obj=>obj.name) : []
    const pageAbout = /(\/|^)(About|Pricing|Features|Contact|Product|Terms|Disclaimer|Support|Profile)(\/|$)/i.test(pageName);
   
    const [showMenu,setShowMenu] = useState(false)

    const handleSubPageChange = (v) => {
      const selectedSubPage = currentPage?.menu?.find((sub) => sub.name === v)?.link;
      navigate(`${pageName}/${selectedSubPage}`);
    };

    useEffect(()=>{
      setShowMenu(false)
    },[sidebarLeftExpanded])

  return (
    <div className="nav">
    <div className="nav_wrapper" style={{alignItems:"flex-start",flexWrap:"wrap"}}>
 
    <>
    {/* {(["Home",""].includes(pageName) || pageAbout) && !sidebarLeftExpanded &&
   <div className='gradient_text' style={{display:"flex",gap:"0.5rem"}}>
            <Logo color='black' height='30px' width="150px" type='long'/>
            <Typography fontSize={"0.8rem"}>Beta</Typography>
        
        </div>} */}
   {/* {(["Home",""].includes(pageName) || pageAbout) &&
   <div style={{display:"flex",gap:"0.5rem",alignItems:"center",padding:"0.5rem 0.25rem"}}>
        <DemoToggle/>
      
        </div>}
    */}
    {/* {(["Home","","home"].includes(pageName)) &&
      <h3>Autonomy</h3>}
      {(!["Home","","home"].includes(pageName)) &&
      <IconButton onClick={() => navigate("/Home")}>
        <ArrowBackIos/>
      </IconButton>} */}
      
      {(!["Home",""].includes(pageName) && !pageAbout) && 
      <div style={{display:"flex",gap:"1rem",alignItems:"center"}}>
      <Button 
      startIcon={currentPage?.icon}
      onClick={()=>setShowMenu(true)}
      style={{display:"flex",alignItems:"center",padding:"0 0.5rem"}}>
    <h3 style={{color:"var(--selectBlue)"}}>{currentPage?.name}</h3>
    {currentPage?.menu && 
    <ExpandMoreIcon/>}
      
      </Button>

{showMenu && <HoverMenu navMenuSelected={currentPage} 
handleSidebar={(v)=>{setShowMenu(false);navigate(v)}}
handleMouseLeave={()=>setShowMenu(false)}/>}
{/* {pageName === "Todos" && <AddTodo/>} */}

</div>
      }

      
   
      {pageName === "AskAI" && <ChatAIMenu/>}
      
      <div style={{flex:1}}></div>

      <div style={{display: 'flex', alignItems: 'center',gap:"0.75rem",padding:"0.05rem 0.5rem 0 0.5rem"}}>
      {["MyFinancials"].includes(pageName) && 
      <>
      <ProfileCompletion/>
        {/* <MyFinancialHealthScore displayType='icon'/>
        <GoalsSuccessRate displayType='icon'/>
         */}
        </>}

      
      {!["Social","Financial","AskProfessional"].includes(pageName) && <DemoToggle/>}

      {["Analytics","Trade"].includes(pageName) && 
      <PageHeaderIcons name="Explore"/>
      }
      {["Industry","Market"].includes(subPage) &&
      <ActiveTickerButton buttonType={subPage}/>
      }

{pageName === "MyFinancials" &&
      <CurrencySettingsButton/>
      }
      
      {!["Social","AskProfessional"].includes(pageName) &&  <PageHeaderIcons name="Guide" sx={{padding:"0"}}/>}
     

      {/* <SearchNav />
     
      <AlertButton displayType="header"/>
      
      
     */}
      </div>
      
      
    </>

     
   </div>
   
    </div>
  );
}
