import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const HomeBackground = () => {
  const backgrounds = [
    { color: "linear-gradient(135deg, #ffffff, #e6f7ff)", type: "Soft Blue" },
    { color: "linear-gradient(135deg, #ffffff, #f0fff4, #e6fffa)", type: "Soft Green" },
    { color: "linear-gradient(135deg, #ffffff, #fffaf0, #fdf2e9)", type: "Warm Beige" },
    { color: "linear-gradient(135deg, #ffffff, #ffe4e1, #ffeef7)", type: "Gentle Pink" },
    { color: "linear-gradient(135deg, #ffffff, #f8f9fa, #e9ecef)", type: "Cool Gray" },
    { color: "linear-gradient(135deg, #ffffff, #f3e8ff, #ebe4ff)", type: "Lavender Calm" },
    { color: "linear-gradient(135deg, #ffffff, #e6eff7, #fef9e7)", type: "Sky & Sand" },
    { color: "linear-gradient(135deg, #ffffff, #e6f7ff, #e6ffe6)", type: "Ocean Breeze" },
    { color: "linear-gradient(135deg, #ffffff, #fdf6e3, #ffdede)", type: "Dawn Horizon" },
  ];

  const imgaes = [
    "https://images.unsplash.com/photo-1542242476-5a3565835a38?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://plus.unsplash.com/premium_photo-1689962253303-df6f8fd16069?q=80&w=2371&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1436262513933-a0b06755c784?q=80&w=2371&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://images.unsplash.com/photo-1597166532139-5c3075da8739?q=80&w=2524&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://plus.unsplash.com/premium_photo-1664299252185-5d6a0bd34b47?q=80&w=2370&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ]
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
//     }, 5000);

//     return () => clearInterval(interval);
//   }, [backgrounds.length]);

  const currentBackground = backgrounds[4];

  return (
    <>
    {createPortal(<div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        zIndex:1,
        height: "100%",
        background: currentBackground.color,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // fontFamily: "Arial, sans-serif",
        fontSize: "24px",
        fontWeight: "bold",
        color: "#333",
        boxSizing:"border-box",
        overflow:"hidden",
        maxWidth:"100vw",
      }}
    >
        {/* <img src={imgaes[0]} alt="Background" style={{width:"100%",height:"100%",objectFit:"cover"}} /> */}
        {/* {currentBackground.type} */}
    </div>, document.body)}
    
    </>
  );
};

export default HomeBackground;
