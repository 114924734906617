import { useAppInfo } from 'AppState'
import { handleSignOut } from 'auth/useHandleSignout'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import LogoSVG from './LogoSVG'
import LogoLoader from './LogoLoader'

export default function Logo({height="30px",width="25px",type="short",color="white"}) {
  const [mouseEnter,setMouseEnter] = useState(false)
  const {stateApp} = useAppInfo()
  
  function handleLogout(){
    if (stateApp.isLoggedIn && !stateApp.onboarding?.setDisplayName) {
      handleSignOut()
    }
   }
  return (
    <div onClick={()=>handleLogout()} style={{width:width,height:height}}  onMouseEnter={()=>setMouseEnter(true)} 
    onMouseLeave={()=>setMouseEnter(false)} 
    >
    <Link to="/"> 
    {type==="short" && 
    <LogoSVG color={color} height={height} width={width}/>
    // <img src='\LogoShort.png' height={height} width={width} style={{padding:0,borderRadius:"2px"}}
    // />
    
    }
    {type==="loaderShort" && 
    <LogoLoader color={color} height={height} width={width}/>
    }
    {type==="long" && 
      <AutonomyLogo color={color} height={height} width={width}/>      
    }
    </Link> 
    </div>
  )
}



const AutonomyLogo = ({ 
  width = '100%', 
  height = 'auto', 
  color = '#000',
  fullWidth=true
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 500 100"
      xmlns="http://www.w3.org/2000/svg"
       preserveAspectRatio="xMidYMid meet"
      // preserveAspectRatio="xMinYMin meet"
      style={{ display: 'block', maxWidth: '100%' }}
    >
      <text
        x="0"
        y="70"
        // fontFamily="'Montserrat', sans-serif"
        fontSize="72"
        fontWeight="600"
        textLength={fullWidth ? "100%" : undefined} // Use full width if desired
        lengthAdjust={fullWidth ? "spacingAndGlyphs" : undefined} // Adjust spacing and glyphs

        fill={color}
        // style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.2)' }}
      >
        Autonomy
      </text>
    </svg>
  );
};


