import { min } from "lodash";
import { firebaseMedia } from "media/firebaseMedia";
import { generateUniqueId } from "utils/utilGeneral";

export const languages = []
export const languageProficiency = ["Basic", "Conversational", "Fluent", "Native"]

export const professionalCategory = ["Financial Advisor",
    "Financial Planner",
    "Retitrement Planner",
    "Wealth Manager",
    "Portfolio Manger",
    "Investment Advisor",
    "Mortgage Advisor",
    "Insurance Advisor"
    ,"Loan Advisor","Investment Analyst","Trade Analyst","Financial Teacher"]

    
export const professionalType = [
        {
            name: "Financial Advisor",
            url: "FinancialAdvisor",
            coreExpertise: [
                "Holistic Financial Advisory",
                "Goal-Based Financial Planning",
                "Risk Management and Insurance Planning",
                "Tax Efficiency Strategies",
                "Portfolio Diversification"
            ],
            description: "Provides comprehensive financial guidance to help you manage assets, plan for the future, and achieve their financial goals."
        },
        {
            name: "Financial Planner",
            url: "FinancialPlanner",
            coreExpertise: [
                "Family Financial Planning",
                "Cash Flow Management",
                "Retirement Planning",
                "Goal-Based Financial Planning",
                "Budget Management"
            ],
            description: "Helps you create long-term plans to meet life goals through effective budgeting, savings, and retirement strategies."
        },
        {
            name: "Retirement Planner",
            url: "RetirementPlanner",
            coreExpertise: [
                "Retirement Planning",
                "Pension Planning",
                "Social Security Optimization",
                "Retirement Income Management",
                "401(k) and IRA Advisory"
            ],
            description: "Specializes in planning for retirement by managing income streams, optimizing benefits, and preparing for post-retirement needs."
        },
        {
            name: "Wealth Manager",
            url: "WealthManager",
            coreExpertise: [
                "High-Net-Worth Portfolio Management",
                "Legacy and Estate Planning",
                "Alternative Investments Advisory",
                "Risk and Performance Analysis",
                "Tax Efficiency Strategies"
            ],
            description: "Focuses on managing and growing the wealth of high-net-worth you through tailored investment and estate planning strategies."
        },
        {
            name: "Portfolio Manager",
            url: "PortfolioManager",
            coreExpertise: [
                "Equity Portfolio Management",
                "Fixed Income Portfolio Management",
                "Investment Strategy Development",
                "Asset Allocation",
                "Risk and Performance Analysis"
            ],
            description: "Oversees investment portfolios to maximize returns while managing risk, using tailored asset allocation and investment strategies."
        },
        {
            name: "Investment Advisor",
            url: "InvestmentAdvisor",
            coreExpertise: [
                "Investment Strategy Development",
                "Portfolio Diversification",
                "Market Trend Analysis",
                "Equity Portfolio Management",
                "Alternative Asset Management"
            ],
            description: "Provides guidance on investment choices and strategies, with a focus on portfolio diversification and market trends."
        },
        {
            name: "Mortgage Advisor",
            url: "MortgageAdvisor",
            coreExpertise: [
                "Home Purchase Financing",
                "Refinancing Options",
                "Loan Pre-Approval",
                "Credit Assessment for Mortgage",
                "Debt Consolidation"
            ],
            description: "Assists you in navigating the mortgage process, offering expertise in financing options, refinancing, and loan approvals."
        },
        {
            name: "Insurance Advisor",
            url: "InsuranceAdvisor",
            coreExpertise: [
                "Risk Management and Insurance Planning",
                "Life Insurance Planning",
                "Health Insurance Advisory",
                "Property and Casualty Insurance",
                "Long-Term Care Insurance"
            ],
            description: "Helps you understand and select appropriate insurance options to protect assets and manage personal risks."
        },
        {
            name: "Loan Advisor",
            url: "LoanAdvisor",
            coreExpertise: [
                "Personal Loan Consulting",
                "Business Loan Advisory",
                "Student Loan Refinancing",
                "Debt Consolidation",
                "Credit Assessment for Mortgage"
            ],
            description: "Provides consulting on various loan options, focusing on debt management, refinancing, and credit assessments."
        },
        {
            name: "Investment Analyst",
            url: "InvestmentAnalyst",
            coreExpertise: [
                "Equity Analysis",
                "Bond Market Analysis",
                "Market Trend Analysis",
                "Economic Forecasting",
                "Industry Sector Research"
            ],
            description: "Conducts in-depth research and analysis of markets and investments to guide informed investment decisions."
        },
        {
            name: "Trade Analyst",
            url: "TradeAnalyst",
            coreExpertise: [
                "Stock Trading Strategies",
                "Commodity Market Analysis",
                "Foreign Exchange Trading",
                "Risk Management in Trading",
                "Economic Forecasting"
            ],
            description: "Focuses on analyzing and developing strategies for trading in various financial markets, including stocks and commodities."
        },
        {
            name: "Financial Teacher",
            url: "FinancialTeacher",
            coreExpertise: [
                "Budget Management",
                "Cash Flow Management",
                "Goal-Based Financial Planning",
                "Family Financial Planning",
                "Personal Loan Consulting"
            ],
            description: "Educates individuals or groups on personal finance topics, aiming to improve financial literacy and budgeting skills."
        }
    ];
    
    export const coreExpertise = [
        "Holistic Financial Advisory",
        "Retirement Planning",
        "Tax Efficiency Strategies",
        "Family Financial Planning",
        "Budget Management",
        "Goal-Based Financial Planning",
        "Cash Flow Management",
        "Risk Management and Insurance Planning",
        "Pension Planning",
        "401(k) and IRA Advisory",
        "Social Security Optimization",
        "Retirement Income Management",
        "High-Net-Worth Portfolio Management",
        "Legacy and Estate Planning",
        "Philanthropy and Charitable Giving",
        "Alternative Investments Advisory",
        "Equity Portfolio Management",
        "Fixed Income Portfolio Management",
        "Alternative Asset Management",
        "Risk and Performance Analysis",
        "Investment Strategy Development",
        "Asset Allocation",
        "Portfolio Diversification",
        "Market Trend Analysis",
        "Home Purchase Financing",
        "Refinancing Options",
        "Loan Pre-Approval",
        "Credit Assessment for Mortgage",
        "Life Insurance Planning",
        "Health Insurance Advisory",
        "Property and Casualty Insurance",
        "Long-Term Care Insurance",
        "Personal Loan Consulting",
        "Business Loan Advisory",
        "Student Loan Refinancing",
        "Debt Consolidation",
        "Equity Analysis",
        "Bond Market Analysis",
        "Industry Sector Research",
        "Economic Forecasting",
        "Stock Trading Strategies",
        "Commodity Market Analysis",
        "Foreign Exchange Trading",
        "Risk Management in Trading",
        "Alternative Asset Analysis",
        "Philanthropic Advisory"
    ];
    

const date = new Date();

export const professionalDetailsArray = [
    {
        userId: 1,
        loginDate: date,
        name: "Maria Doe",
        organization: "JP Morgan Chase & Co.",
        organizationAchievements: "Financial Insights Inc. has this many clients...",
        role: "Financial Advisor",
        professionalCategory: ["Financial Advisor", "Financial Planner"],
        coreExpertise: ["Retirement Planning", "Wealth Management", "Estate Planning"],
        descriptionShort: "I can help you with Financial Planning and Retirement Planning.",
        descriptionLong: "With over 10 years of experience in financial planning and retirement strategies, I help individuals and families achieve financial security and long-term goals. I specialize in personalized plans, investment management, tax-efficient strategies, and sustainable retirement income. My approach empowers you to make confident decisions while balancing current needs and future aspirations. Let’s work together to secure your financial future.",
        services: [
          { title: "Family Financial Planning", description: ""},
          { title: "Retirement Planning", description: ""}
        ],
        locationServed: ["New York", "New Jersey", "Connecticut"],
        languages: [{ language: "", proficiency: "" }],
        profileImage: firebaseMedia?.professional_Maria,
        marketingMedia: [{fileType:"image",url:firebaseMedia?.professional_Chase},
          {fileType:"image",url:firebaseMedia?.professional_Chase},
          {fileType:"image",url:"https://images.unsplash.com/photo-1527689368864-3a821dbccc34?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fG9mZmljZSUyMHRlYW18ZW58MHx8MHx8fDA%3D"},
        ],
        website: "",
        socialMedia: {
            instagram: "https://instagram.com/johndoe_advisor",
            linkedin: "https://linkedin.com/in/johndoe",
            facebook: "https://facebook.com/johndoeadvisor",
            whatsapp: "https://wa.me/1234567890"
          }
        ,
        address: "123 Finance St, Suite 101, New York, NY, 10001",
        location: { city: "", state: "", country: "" },
        email: "johndoe@example.com",
        phoneNumber: "+1-123-456-7890",
        availability: [{ date: "", time: "" }],
        responseTime: "",
        minimumFee: "$250/hr",
        fees: [
          { basic: "$100/hr", details: [] },
          { standard: "$150/hr", details: [] },
          { premium: "$200/hr", details: [] },
          { ultra: "$300/hr", details: [] }
        ],
        certifications: [
          {
            issuingAuthority: "Certified Financial Planner Board",
            issuedDate: "2020-01-15",
            expiryDate: "-"
          },
          {
            issuingAuthority: "Chartered Financial Analyst Institute",
            issuedDate: "2020-01-15",
            expiryDate: "-"
          }
        ],
        qualifications: [
          {
            school: "Duke University",
            degree: "Master of Business Administration",
            issuedDate: "2020-01-15",
          },
        ],
        showDetails: [{ email: true, phone: false, website: true, location: true, socialMedia: true }],
        faq: [{ question: "", answer: "" }],
        reviews: [
          {
            star: 0,
            comment: "",
            location: "",
            date: ""
          },
        ],
        reviewsStats: {
          averageRating: 4.2,
          totalReviews: 200,
          starCounts: { 5: 15, 4: 0, 3: 1, 2: 0, 1: 0 },
                },
        categoryRatings: [
                  { label: 'Communication Level', rating: 4.6 },
                  { label: 'Quality of delivery', rating: 4.8 },
                  { label: 'Value of delivery', rating: 4.9 }
                ],
        averageResponseTime :'1 Hour',
        onlineStatus: 'Offline',
      },
    {
        userId: 2,
        loginDate: date,
        name: "Steve Smith",
        organization: "Fiedility Investments",
        organizationAchievements: "Financial Insights Inc. has this many clients...",
        role: "Senior Financial Advisor",
        professionalCategory: ["Financial Advisor", "Financial Planner"],
        coreExpertise: ["Investments", "Portfolio Management", "Wealth Management"],
        descriptionShort: "I can help with Portfolio Management, Wealth Management and Investment Advice.",
        descriptionLong: "I specialize in portfolio management, wealth management, and investment advice to help individuals and families achieve financial security and long-term goals. My approach focuses on personalized strategies, effective investment management, and actionable insights to empower you to make confident decisions. Let’s work together to secure your financial future.",
        services: [
          { title: "Family Financial Planning", description: ""},
          { title: "Retirement Planning", description: ""}
        ],
        locationServed: ["New York", "New Jersey", "Connecticut"],
        languages: [{ language: "", proficiency: "" }],
        profileImage: firebaseMedia?.professional_Steve,
        marketingMedia: [
          {fileType:"image",url:firebaseMedia?.professional_Fidelity},
          {fileType:"image",url:"https://images.unsplash.com/photo-1527689368864-3a821dbccc34?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fG9mZmljZSUyMHRlYW18ZW58MHx8MHx8fDA%3D"},
        ],
        website: "",
        socialMedia: {
            instagram: "https://instagram.com/johndoe_advisor",
            linkedin: "https://linkedin.com/in/johndoe",
            facebook: "https://facebook.com/johndoeadvisor",
            whatsapp: "https://wa.me/1234567890"
          }
        ,
        address: "123 Finance St, Suite 101, New York, NY, 10001",
        location: { city: "", state: "", country: "" },
        email: "johndoe@example.com",
        phoneNumber: "+1-123-456-7890",
        availability: [{ date: "", time: "" }],
        responseTime: "",
        minimumFee: "$200/hr",
        fees: [
          { basic: "$100/hr", details: [] },
          { standard: "$150/hr", details: [] },
          { premium: "$200/hr", details: [] },
          { ultra: "$300/hr", details: [] }
        ],
        certifications: [
          {
            issuingAuthority: "Certified Financial Planner Board",
            issuedDate: "2020-01-15",
            expiryDate: "-"
          },
          {
            issuingAuthority: "Chartered Financial Analyst Institute",
            issuedDate: "2020-01-15",
            expiryDate: "-"
          }
        ],
        qualifications: [
          {
            school: "Duke University",
            degree: "Master of Business Administration",
            issuedDate: "2020-01-15",
          },
        ],
        showDetails: [{ email: true, phone: false, website: true, location: true, socialMedia: true }],
        faq: [{ question: "", answer: "" }],
        reviews: [
          {
            star: 0,
            comment: "",
            location: "",
            date: ""
          },
        ],
        reviewsStats: {
          averageRating: 4.1,
          totalReviews: 300,
          starCounts: { 5: 15, 4: 0, 3: 1, 2: 0, 1: 0 },
                },
        categoryRatings: [
                  { label: 'Communication Level', rating: 4.6 },
                  { label: 'Quality of delivery', rating: 4.8 },
                  { label: 'Value of delivery', rating: 4.9 }
                ],
        averageResponseTime :'1 Hour',
        onlineStatus: 'Offline',
      },

  {
    userId: 3,
    loginDate: date,
    name: "Brandon Brown",
    organization: "MetLife",
    organizationAchievements: "Financial Insights Inc. has this many clients...",
    role: "Insurance Advisor",
    professionalCategory: ["Insurance Advisor"],
    coreExpertise: ["Insurance", "Life Insurance Planning", "Life Insurance Advice"],
    descriptionShort: "I can help with Life Insurance and Insurance Planning.",
    descriptionLong: "I specialize in life insurance and insurance planning, helping individuals and families secure their financial future. With tailored strategies and expert advice, I ensure you have the right coverage to protect what matters most. Let’s work together to build a plan that provides peace of mind and long-term security.",
    services: [
      { title: "Family Financial Planning", description: ""},
      { title: "Retirement Planning", description: ""}
    ],
    locationServed: ["New York", "New Jersey", "Connecticut"],
    languages: [{ language: "", proficiency: "" }],
    profileImage: firebaseMedia?.professional_Brandon,
    marketingMedia: [{fileType:"image",url:firebaseMedia?.professional_Metlife},
      {fileType:"image",url:"https://images.unsplash.com/photo-1445116572660-236099ec97a0?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNhZmV8ZW58MHx8MHx8fDA%3D"},
      {fileType:"image",url:"https://images.unsplash.com/photo-1527689368864-3a821dbccc34?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fG9mZmljZSUyMHRlYW18ZW58MHx8MHx8fDA%3D"},
    ],
    website: "",
    socialMedia: {
        instagram: "https://instagram.com/johndoe_advisor",
        linkedin: "https://linkedin.com/in/johndoe",
        facebook: "https://facebook.com/johndoeadvisor",
        whatsapp: "https://wa.me/1234567890"
      }
    ,
    address: "123 Finance St, Suite 101, New York, NY, 10001",
    location: { city: "", state: "", country: "" },
    email: "johndoe@example.com",
    phoneNumber: "+1-123-456-7890",
    availability: [{ date: "", time: "" }],
    responseTime: "",
    minimumFee: "$150/hr",
    fees: [
      { basic: "$100/hr", details: [] },
      { standard: "$150/hr", details: [] },
      { premium: "$200/hr", details: [] },
      { ultra: "$300/hr", details: [] }
    ],
    certifications: [
      {
        issuingAuthority: "Certified Financial Planner Board",
        issuedDate: "2020-01-15",
        expiryDate: "-"
      },
      {
        issuingAuthority: "Chartered Financial Analyst Institute",
        issuedDate: "2020-01-15",
        expiryDate: "-"
      }
    ],
    qualifications: [
      {
        school: "Duke University",
        degree: "Master of Business Administration",
        issuedDate: "2020-01-15",
      },
    ],
    showDetails: [{ email: true, phone: false, website: true, location: true, socialMedia: true }],
    faq: [{ question: "", answer: "" }],
    reviews: [
      {
        star: 0,
        comment: "",
        location: "",
        date: ""
      },
    ],
    reviewsStats: {
      averageRating: 4.5,
      totalReviews: 50,
      starCounts: { 5: 15, 4: 0, 3: 1, 2: 0, 1: 0 },
            },
    categoryRatings: [
              { label: 'Communication Level', rating: 4.6 },
              { label: 'Quality of delivery', rating: 4.8 },
              { label: 'Value of delivery', rating: 4.9 }
            ],
    averageResponseTime :'1 Hour',
    onlineStatus: 'Offline',
  },
  {
    userId: 4,
    loginDate: date,
    name: "Diana Adams",
    organization: "Rocket Mortgage",
    organizationAchievements: "Financial Insights Inc. has this many clients...",
    role: "Mortgage Advisor",
    professionalCategory: ["Mortgage Advisor", "Financial Planner"],
    coreExpertise: ["Mortgages", "Financial Planning"],
    descriptionShort: "I can help with Mortgage and Financial Planning.",
    descriptionLong: "I specialize in mortgages and financial planning, helping individuals and families secure their financial future. With tailored strategies and expert advice, I ensure you have the right mortgage. Let’s work together to build a plan that provides peace of mind and long-term security.",
    services: [
      { title: "Family Financial Planning", description: ""},
      { title: "Retirement Planning", description: ""}
    ],
    locationServed: ["New York", "New Jersey", "Connecticut"],
    languages: [{ language: "", proficiency: "" }],
    profileImage: firebaseMedia?.professional_Diana,
    marketingMedia: [{fileType:"image",url:firebaseMedia?.professional_Rocket},
      {fileType:"image",url:"https://images.unsplash.com/photo-1445116572660-236099ec97a0?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNhZmV8ZW58MHx8MHx8fDA%3D"},
      {fileType:"image",url:"https://images.unsplash.com/photo-1527689368864-3a821dbccc34?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fG9mZmljZSUyMHRlYW18ZW58MHx8MHx8fDA%3D"},
    ],
    website: "",
    socialMedia: {
        instagram: "https://instagram.com/johndoe_advisor",
        linkedin: "https://linkedin.com/in/johndoe",
        facebook: "https://facebook.com/johndoeadvisor",
        whatsapp: "https://wa.me/1234567890"
      }
    ,
    address: "123 Finance St, Suite 101, New York, NY, 10001",
    location: { city: "", state: "", country: "" },
    email: "johndoe@example.com",
    phoneNumber: "+1-123-456-7890",
    availability: [{ date: "", time: "" }],
    responseTime: "",
    minimumFee: "$150/hr",
    fees: [
      { basic: "$100/hr", details: [] },
      { standard: "$150/hr", details: [] },
      { premium: "$200/hr", details: [] },
      { ultra: "$300/hr", details: [] }
    ],
    certifications: [
      {
        issuingAuthority: "Certified Financial Planner Board",
        issuedDate: "2020-01-15",
        expiryDate: "-"
      },
      {
        issuingAuthority: "Chartered Financial Analyst Institute",
        issuedDate: "2020-01-15",
        expiryDate: "-"
      }
    ],
    qualifications: [
      {
        school: "Duke University",
        degree: "Master of Business Administration",
        issuedDate: "2020-01-15",
      },
    ],
    showDetails: [{ email: true, phone: false, website: true, location: true, socialMedia: true }],
    faq: [{ question: "", answer: "" }],
    reviews: [
      {
        star: 0,
        comment: "",
        location: "",
        date: ""
      },
    ],
    reviewsStats: {
      averageRating: 4.8,
      totalReviews: 16,
      starCounts: { 5: 15, 4: 0, 3: 1, 2: 0, 1: 0 },
            },
    categoryRatings: [
              { label: 'Communication Level', rating: 4.6 },
              { label: 'Quality of delivery', rating: 4.8 },
              { label: 'Value of delivery', rating: 4.9 }
            ],
    averageResponseTime :'1 Hour',
    onlineStatus: 'Offline',
  },
  // {
  //   userId: 5,
  //   loginDate: date,
  //   name: "Kathlyn",
  //   organization: "Capital One",
  //   organizationAchievements: "Financial Insights Inc. has this many clients...",
  //   role: "Financial Advisor",
  //   professionalCategory: ["Financial Advisor", "Financial Planner","Mortgage Advisor"],
  //   coreExpertise: ["Financial Planning", "Goal Setting", "Ongoing Planning"],
  //   descriptionShort: "I can help with your overall financial well-being.",
  //   descriptionLong: "With over 5 years of experience in financial planning and retirement strategies, I help individuals and families achieve financial security and long-term goals. I specialize in personalized plans, investment management, tax-efficient strategies, and sustainable retirement income. My approach empowers you to make confident decisions while balancing current needs and future aspirations. Let’s work together to secure your financial future.",
  //   services: [
  //     { title: "Family Financial Planning", description: ""},
  //     { title: "Retirement Planning", description: ""}
  //   ],
  //   locationServed: ["New York", "New Jersey", "Connecticut"],
  //   languages: [{ language: "", proficiency: "" }],
  //   profileImage: "https://firebasestorage.googleapis.com/v0/b/takestock-9e486.appspot.com/o/images%2FProfessional5.jpg?alt=media&token=e8ff3672-3f2c-47e9-b78a-dade518dd2cd",
  //   marketingMedia: [{fileType:"image",url:"https://firebasestorage.googleapis.com/v0/b/takestock-9e486.appspot.com/o/images%2FCapital1.png?alt=media&token=f2f8c3c6-4b50-4904-b2d1-71d4bc25597e"},
  //     {fileType:"image",url:"https://images.unsplash.com/photo-1445116572660-236099ec97a0?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNhZmV8ZW58MHx8MHx8fDA%3D"},
  //     {fileType:"image",url:"https://images.unsplash.com/photo-1527689368864-3a821dbccc34?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fG9mZmljZSUyMHRlYW18ZW58MHx8MHx8fDA%3D"},
  //   ],
  //   website: "",
  //   socialMedia: {
  //       instagram: "https://instagram.com/johndoe_advisor",
  //       linkedin: "https://linkedin.com/in/johndoe",
  //       facebook: "https://facebook.com/johndoeadvisor",
  //       whatsapp: "https://wa.me/1234567890"
  //     }
  //   ,
  //   address: "123 Finance St, Suite 101, New York, NY, 10001",
  //   location: { city: "", state: "", country: "" },
  //   email: "johndoe@example.com",
  //   phoneNumber: "+1-123-456-7890",
  //   availability: [{ date: "", time: "" }],
  //   responseTime: "",
  //   minimumFee: "$150/hr",
  //   fees: [
  //     { basic: "$100/hr", details: [] },
  //     { standard: "$150/hr", details: [] },
  //     { premium: "$200/hr", details: [] },
  //     { ultra: "$300/hr", details: [] }
  //   ],
  //   certifications: [
  //     {
  //       issuingAuthority: "Certified Financial Planner Board",
  //       issuedDate: "2020-01-15",
  //       expiryDate: "-"
  //     },
  //     {
  //       issuingAuthority: "Chartered Financial Analyst Institute",
  //       issuedDate: "2020-01-15",
  //       expiryDate: "-"
  //     }
  //   ],
  //   qualifications: [
  //     {
  //       school: "Duke University",
  //       degree: "Master of Business Administration",
  //       issuedDate: "2020-01-15",
  //     },
  //   ],
  //   showDetails: [{ email: true, phone: false, website: true, location: true, socialMedia: true }],
  //   faq: [{ question: "", answer: "" }],
  //   reviews: [
  //     {
  //       star: 0,
  //       comment: "",
  //       location: "",
  //       date: ""
  //     },
  //   ],
  //   reviewsStats: {
  //     averageRating: 4.1,
  //     totalReviews: 20,
  //     starCounts: { 5: 15, 4: 0, 3: 1, 2: 0, 1: 0 },
  //           },
  //   categoryRatings: [
  //             { label: 'Communication Level', rating: 4.6 },
  //             { label: 'Quality of delivery', rating: 4.8 },
  //             { label: 'Value of delivery', rating: 4.9 }
  //           ],
  //   averageResponseTime :'1 Hour',
  //   onlineStatus: 'Offline',
  // },
  // {
  //   userId: 6,
  //   loginDate: date,
  //   name: "Elise Owen",
  //   organization: "Bank of America",
  //   organizationAchievements: "Financial Insights Inc. has this many clients...",
  //   role: "Insurance Advisor",
  //   professionalCategory: ["Insurance Advisor", "Financial Planner"],
  //   coreExpertise: ["Insurance", "Life Insurance Planning", "Life Insurance Advice"],
  //   descriptionShort: "I can help with Life Insurance and Insurance Planning.",
  //   descriptionLong: "I specialize in life insurance and insurance planning, helping individuals and families secure their financial future. With tailored strategies and expert advice, I ensure you have the right coverage to protect what matters most. Let’s work together to build a plan that provides peace of mind and long-term security.",
  //   services: [
  //     { title: "Family Financial Planning", description: ""},
  //     { title: "Retirement Planning", description: ""}
  //   ],
  //   locationServed: ["New York", "New Jersey", "Connecticut"],
  //   languages: [{ language: "", proficiency: "" }],
  //   profileImage: "https://firebasestorage.googleapis.com/v0/b/takestock-9e486.appspot.com/o/images%2FProfessional4.jpg?alt=media&token=590298d3-57f1-4145-a24c-f0ba057d8574",
  //   marketingMedia: [{fileType:"image",url:"https://firebasestorage.googleapis.com/v0/b/takestock-9e486.appspot.com/o/images%2FBank-of-America-Emblem.png?alt=media&token=8ca6336c-2dc9-4750-8b53-890ce313a31a"},
  //     {fileType:"image",url:"https://images.unsplash.com/photo-1445116572660-236099ec97a0?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNhZmV8ZW58MHx8MHx8fDA%3D"},
  //     {fileType:"image",url:"https://images.unsplash.com/photo-1527689368864-3a821dbccc34?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fG9mZmljZSUyMHRlYW18ZW58MHx8MHx8fDA%3D"},
  //   ],
  //   website: "",
  //   socialMedia: {
  //       instagram: "https://instagram.com/johndoe_advisor",
  //       linkedin: "https://linkedin.com/in/johndoe",
  //       facebook: "https://facebook.com/johndoeadvisor",
  //       whatsapp: "https://wa.me/1234567890"
  //     }
  //   ,
  //   address: "123 Finance St, Suite 101, New York, NY, 10001",
  //   location: { city: "", state: "", country: "" },
  //   email: "johndoe@example.com",
  //   phoneNumber: "+1-123-456-7890",
  //   availability: [{ date: "", time: "" }],
  //   responseTime: "",
  //   minimumFee: "$100/hr",
  //   fees: [
  //     { basic: "$100/hr", details: [] },
  //     { standard: "$150/hr", details: [] },
  //     { premium: "$200/hr", details: [] },
  //     { ultra: "$300/hr", details: [] }
  //   ],
  //   certifications: [
  //     {
  //       issuingAuthority: "Certified Financial Planner Board",
  //       issuedDate: "2020-01-15",
  //       expiryDate: "-"
  //     },
  //     {
  //       issuingAuthority: "Chartered Financial Analyst Institute",
  //       issuedDate: "2020-01-15",
  //       expiryDate: "-"
  //     }
  //   ],
  //   qualifications: [
  //     {
  //       school: "Duke University",
  //       degree: "Master of Business Administration",
  //       issuedDate: "2020-01-15",
  //     },
  //   ],
  //   showDetails: [{ email: true, phone: false, website: true, location: true, socialMedia: true }],
  //   faq: [{ question: "", answer: "" }],
  //   reviews: [
  //     {
  //       star: 0,
  //       comment: "",
  //       location: "",
  //       date: ""
  //     },
  //   ],
  //   reviewsStats: {
  //     averageRating: 4.4,
  //     totalReviews: 400,
  //     starCounts: { 5: 38, 4: 0, 3: 1, 2: 0, 1: 0 },
  //           },
  //   categoryRatings: [
  //             { label: 'Communication Level', rating: 4.6 },
  //             { label: 'Quality of delivery', rating: 4.8 },
  //             { label: 'Value of delivery', rating: 4.9 }
  //           ],
  //   averageResponseTime :'1 Hour',
  //   onlineStatus: 'Offline',
  // },

]

export const financeProfessionalFormDetailTemplate = {
          // userId: generateUniqueId(),
          loginDate: date,
          name: "Maria Doe",
          organization: "Financial Insights Inc.",
          organizationAchievements: "Financial Insights Inc. has this many clients...",
          role: "Senior Financial Advisor",
          professionalCategory: ["Financial Advisor", "Financial Planner"],
          coreExpertise: ["Retirement Planning", "Wealth Management", "Estate Planning"],
          descriptionShort: "I can help with Financial Planning and Retirement Planning.",
          descriptionLong: "With over 10 years of experience in financial planning and retirement strategies, I help individuals and families achieve financial security and long-term goals. I specialize in personalized plans, investment management, tax-efficient strategies, and sustainable retirement income. My approach empowers you to make confident decisions while balancing current needs and future aspirations. Let’s work together to secure your financial future.",
          services: [
            { title: "Family Financial Planning", description: ""},
            { title: "Retirement Planning", description: ""}
          ],
          locationServed: ["New York", "New Jersey", "Connecticut"],
          languages: [{ language: "", proficiency: "" }],
          profileImage: "https://plus.unsplash.com/premium_photo-1670884442927-e647436e12ff?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fHByb2Zlc3Npb25hbHxlbnwwfHwwfHx8MA%3D%3D",
          marketingMedia: [{fileType:"image",url:"https://images.unsplash.com/photo-1505409859467-3a796fd5798e?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8b2ZmaWNlJTIwc3BhY2V8ZW58MHx8MHx8fDA%3D"},
            {fileType:"image",url:"https://images.unsplash.com/photo-1445116572660-236099ec97a0?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGNhZmV8ZW58MHx8MHx8fDA%3D"},
            {fileType:"image",url:"https://images.unsplash.com/photo-1527689368864-3a821dbccc34?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fG9mZmljZSUyMHRlYW18ZW58MHx8MHx8fDA%3D"},
          ],
          website: "",
          socialMedia: {
              instagram: "https://instagram.com/johndoe_advisor",
              linkedin: "https://linkedin.com/in/johndoe",
              facebook: "https://facebook.com/johndoeadvisor",
              whatsapp: "https://wa.me/1234567890"
            }
          ,
          address: "123 Finance St, Suite 101, New York, NY, 10001",
          location: { city: "", state: "", country: "" },
          email: "johndoe@example.com",
          phoneNumber: "+1-123-456-7890",
          availability: [{ date: "", time: "" }],
          responseTime: "",
          fees: [
            { basic: "$100/hr", details: [] },
            { standard: "$150/hr", details: [] },
            { premium: "$200/hr", details: [] },
            { ultra: "$300/hr", details: [] }
          ],
          certifications: [
            {
              issuingAuthority: "Certified Financial Planner Board",
              issuedDate: "2020-01-15",
              expiryDate: "-"
            },
            {
              issuingAuthority: "Chartered Financial Analyst Institute",
              issuedDate: "2020-01-15",
              expiryDate: "-"
            }
          ],
          qualifications: [
            {
              school: "Duke University",
              degree: "Master of Business Administration",
              issuedDate: "2020-01-15",
            },
          ],
          showDetails: [{ email: true, phone: false, website: true, location: true, socialMedia: true }],
          faq: [{ question: "", answer: "" }],
          reviews: [
            {
              star: 0,
              comment: "",
              location: "",
              date: ""
            },
          ],
          reviewsStats: {
            averageRating: 4.8,
            totalReviews: 16,
            starCounts: { 5: 15, 4: 0, 3: 1, 2: 0, 1: 0 },
                  },
          categoryRatings: [
                    { label: 'Communication Level', rating: 4.6 },
                    { label: 'Quality of delivery', rating: 4.8 },
                    { label: 'Value of delivery', rating: 4.9 }
                  ],
          averageResponseTime :'1 Hour',
          onlineStatus: 'Offline',
        }