import { Button } from "@mui/material";
import Video from "components/Media/Video";
import TabLine from "components/Tabs/TabLine";
import GlowBackground from "home/GlowBackground";
import { firebaseMedia } from "media/firebaseMedia";
import React, { useEffect, useRef, useState } from "react";

export default function FeatureAskAI () {
    return (
        <div style={{display:"flex",flexDirection:"column"
            ,padding:"2rem 4rem",paddingTop:"4rem",alignItems:"center"
            ,width:"100%",boxSizing:"border-box",position:"relative"}}>
 <div style={{paddingBottom:"4rem",textAlign:"center"}}>
 <h1 style={{ color: "black",fontSize:"4rem"}}>Ask AI Nomy</h1>
 <h1 style={{fontSize:"3rem"}}>personal questions</h1>
 </div>
 <BackgroundMessageVideoSection/>
        </div>
    )
}


const Typewriter = ({ message = '', onComplete, speed = 30,fontSize="2rem"}) => {
    const [displayedText, setDisplayedText] = useState('');
    const indexRef = useRef(0);
    const rafIdRef = useRef(null);
    const startTimeRef = useRef(null);
  
    useEffect(() => {
      if (!message) return;
  
      setDisplayedText('');
      indexRef.current = 0;
      startTimeRef.current = null;
  
      const typeCharacter = (timestamp) => {
        if (!startTimeRef.current) {
          startTimeRef.current = timestamp;
        }
  
        const elapsed = timestamp - startTimeRef.current;
  
        // Check if it's time to display the next character
        if (elapsed >= speed * indexRef.current) {
          setDisplayedText(message.slice(0, indexRef.current + 1));
          indexRef.current += 1;
        }
  
        if (indexRef.current < message.length) {
          rafIdRef.current = requestAnimationFrame(typeCharacter);
        } else {
          if (onComplete) onComplete();
          cancelAnimationFrame(rafIdRef.current);
          rafIdRef.current = null;
        }
      };
  
      rafIdRef.current = requestAnimationFrame(typeCharacter);
  
      // Cleanup on unmount or when message changes
      return () => {
        if (rafIdRef.current) {
          cancelAnimationFrame(rafIdRef.current);
        }
      };
    }, [message, speed]);
  
    return <h1 style={{ color: '#000',fontSize:fontSize}}>{displayedText}</h1>;
  };
  
  
  const BackgroundMessageVideoSection = () => {
    return (
      <FullWidthCarousel sections={sections} />
    );
  };
  
  const sections = [
    {
      message: "How am I doing financially?",
      videoUrl: firebaseMedia?.video_howfinancially,
      imageUrl: "https://images.unsplash.com/photo-1518130242561-edb760734bee?q=80&w=2535&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      messageAlign: "left",
    },
    {
      message: "Can I afford a million-dollar house?",
      videoUrl: firebaseMedia?.video_canibuyhouse,
      imageUrl: "https://images.unsplash.com/photo-1501004318641-b39e6451bec6?q=80&w=2273&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      messageAlign: "left",
    },
    {
      message: "Am I beating the market?",
      videoUrl: firebaseMedia?.video_amibeatingmarket,
      imageUrl: "https://images.unsplash.com/photo-1466781783364-36c955e42a7f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cGxhbnR8ZW58MHx8MHx8fDA%3D",
      messageAlign: "left",
    },
    {
      message: "Find me opportunities aligned with my portfolio strategy?",
      videoUrl: firebaseMedia?.video_portfoliofit,
      imageUrl: "https://images.unsplash.com/photo-1604762525343-62309354f8a1?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      messageAlign: "left",
    },
  ];
  
  
  // "https://images.unsplash.com/photo-1605449670493-ca1d812d0488?q=80&w=2536&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  
  
  const CarouselSection = ({ section, active }) => {
    const [typingCompleted, setTypingCompleted] = useState(false);
    const [messageInView, setMessageInView] = useState(false);
  
    useEffect(() => {
      if (active) {
        setMessageInView(true);
      } else {
        setMessageInView(false);
        setTypingCompleted(false); // Reset typing state on slide change
      }
    }, [active]);
  
  
    return (
      <div
        style={{
        //   minHeight: '100vh',
          width: '100%',
        //   background: "white",
          position: 'relative',
          top: 0,
          left: active ? '0%' : '100%',
          // transition: 'left 1s ease-in-out',
          padding: "3rem",
          boxSizing: "border-box",
          zIndex: active ? 1 : 0,
        }}
      >
  
          {messageInView && (
            <div
              style={{
                backdropFilter: "blur(30px)",
                width: '100%',
                zIndex: 200,
                height: "100%",
                position: 'relative',
                padding: '20px',
                // borderRadius: '10px',
                // display: 'flex',
                // flexDirection: 'column',
                gap: '1rem',
                opacity: messageInView ? 1 : 0,
                transition: 'opacity 1s ease-in-out',
                display:"flex",flexDirection:"column",alignItems:"flex-start",gap:"1rem"
              }}
            >
              <div
                style={{
                  background: '#f7f7f7',
                  borderRadius: '5px',
                  padding: '1rem',
                  width: 'max-content',
                  // marginLeft: section.messageAlign === 'left' ? 'auto' : '0',
                  maxWidth: '600px',
                  opacity: messageInView ? 1 : 0,
                  transition: 'opacity 1s ease-in-out',
                }}
              >
                <Typewriter
                  message={section.message}
                  onComplete={() => setTypingCompleted(true)}
                />
              </div>
  
              
  
              {typingCompleted && (
                
                <div
                  className='fade-in'
                  style={{
                    borderRadius: '5px',
                    // padding: '1rem',
                    height:"auto",
                    width: '80%',
                    // marginLeft: section.messageAlign !== 'left' ? 'auto' : '0',
                    opacity: typingCompleted ? 1 : 0,
                    transition: 'opacity 4s ease-in-out',
                  }}
                >
                 <Video 
                    src={section.videoUrl}
                    sx={{height:"auto",boxShadow:"none"}}
                  />
                </div>
              )}
            </div>
          )}
  
      </div>
    );
  };
  
  const FullWidthCarousel = ({ sections, delay = 14000 }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
  
    // useEffect(() => {
    //   if (!isPaused) {
    //     const intervalId = setInterval(() => {
    //       setActiveIndex((prevIndex) => (prevIndex + 1) % sections.length);
    //     }, delay); // Change every 14 seconds (14,000 ms)
  
    //     return () => clearInterval(intervalId); // Clean up on unmount
    //   }
    // }, [sections.length, delay, isPaused]);
  
    const handleNext = () => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % sections.length);
    };
  
    const handlePrev = () => {
      setActiveIndex((prevIndex) =>
        prevIndex === 0 ? sections.length - 1 : prevIndex - 1
      );
    };
    const questions = sections.map((section) => section.message);
   // backgroundColor: "#041F1B"

    return (
      <div style={{ width: '100%', minHeight: '100vh', position: 'relative', 
      overflow: 'hidden',display:"flex",flexDirection:"column",alignItems:"center"
      ,background:"#fafafa"
      ,borderRadius:"25px",padding:"1rem 0"}}>
        <>
        <TabLine 
        variant='noLine'
        // tabSx={{background:"#041F1B",color:"white"}}   
        // tabsSx={{background:"#041F1B"}}
        tabList={questions} tabIndex={activeIndex} handleTabChange={(i)=>setActiveIndex(i)}/></>

        {sections.map((section, index) => (
          <React.Fragment key={index}>
          {index === activeIndex &&
          <CarouselSection
            section={section}
            active={index === activeIndex}
          />}
          </React.Fragment>
        ))}

        {/* Navigation Buttons */}
        {/* <div style={{ position: 'absolute', 
          top: '50%',display: 'flex', 
          justifyContent: 'flex-end',gap:"1rem",padding: '0 2rem',right:"10px",zIndex:100}}>
          <button 
            onClick={() => {
              handlePrev();
              setIsPaused(true); // Pause auto-transition when manually navigating
            }}
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.6)',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
            }}
          >
            Previous
          </button>
          <button 
            onClick={() => {
              handleNext();
              setIsPaused(true); // Pause auto-transition when manually navigating
            }}
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.6)',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '16px',
            }}
          >
            Next
          </button>
        </div> */}
      </div>
    );
  };
    