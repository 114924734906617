import React, { useEffect, useState } from "react";
import {Avatar,Stack,Button,} from "@mui/material";
import Modal from "components/Modal/Modal";
import { useAppInfo } from "AppState";
import useUpdateOnboarding from "data/user/onBoarding/useUpdateOnboarding";
import useAddFinancials from "data/user/financials/useAddFinancials";
import { firebaseMedia } from "media/firebaseMedia";



export default function DemoAccounts ({openModal}) {
    const {stateMyFinancials,stateApp,dispatchMyFinancials} = useAppInfo()
    const {username,onboarding} = stateApp 
    const {financialView,demoProfile,demoProfileModal} = stateMyFinancials
    const {addFinancials} = useAddFinancials()
    const UpdateOnboarding = useUpdateOnboarding()
    const [demoProfileSelected,setDemoProfileSelected] = useState()
 
    useEffect(()=>{
      setDemoProfileSelected(demoProfile)
    },[demoProfile])


  const [mouseEnter,setMouseEnter] = useState(-1)   
  
  function handleOnboarding(){
    if (!onboarding?.financials){
    const updatedOnboarding = {...onboarding,financials:true,financialView:"demo",demoProfile:demoProfileSelected}
    UpdateOnboarding(username,updatedOnboarding);}
    else{
      const updatedOnboarding = {...onboarding,financialView:"demo",demoProfile:demoProfileSelected}
      UpdateOnboarding(username,updatedOnboarding); 
    }
  }
  function handleModal(){
    dispatchMyFinancials({demoProfileModal:false,financialView:"demo", demoProfile: demoProfileSelected})
    handleOnboarding() 
    // addFinancials(demoProfileSelected,"demoProfile")
  }
 
  function handleDemoProfile(v){
    setDemoProfileSelected(v)
  }

  return (
    <>
    {(demoProfileModal || openModal) &&
    <Modal sx={{background:"black",padding:"0.5rem"}} 
    width="80%" height="80%" onClose={()=>handleModal(false)}>
    <div style={{width:"100%",height:"100%",overflow:"auto",display:"flex",flexDirection:"column"
        ,gap:"1rem",alignItems:"center"}}>
    <div style={{padding:"2rem",borderRadius:"25px"}}>
    <h1 style={{color:"white"}}>{`Hey ${username &&username?.split(" ")[0]},`}</h1>
    {/* <h1 style={{color:"white"}}>Get Started with Demo Account</h1><br/> */}
<h3 style={{color:"white"}}>Select one of the demo profiles below to get started.</h3>
<h3 style={{color:"white"}}>When you're ready, connect your financials to unlock a fully personalized experience.</h3>
</div>
    <div
      style={{
        display: "flex",
        gap: "2rem",
        flexWrap: "wrap",
        justifyContent: "center",
        padding: "2rem"
      }}
    >
     
      {demoAccounts.map((account, index) => (
        <div
          key={index}
          onClick={() =>handleDemoProfile(account.name)}
          onMouseEnter={(e) => setMouseEnter(index)}
        onMouseLeave={(e) => setMouseEnter(-1)}
          style={{
            width: "100%",
            maxWidth: "450px",
            border: "1px solid #e0e0e0",
            borderRadius: "12px",
            padding: "1.5rem",
            backgroundColor: "#fafafa",
            userSelect:"none",
            cursor:"pointer",
            boxSizing:"border-box",
            border:account.name === demoProfileSelected && "4px solid #1976d2",
            boxShadow:(mouseEnter === index && account.name !== demoProfileSelected) ? "0 8px 15px rgba(0, 0, 0, 0.2)" : account.name === demoProfileSelected && "0 2px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Stack spacing={2} alignItems="center">
            <Avatar
              src={account?.img}
              sx={{
                bgcolor: "none",
                height: "100px",
                width: "100px",
                fontSize: "2.5rem",
              }}
            >
              {account.avatar}
            </Avatar>
            <div style={{ textAlign: "center" }}>
              <h2 style={{ margin: "0.5rem 0" }}>{account.name}</h2>
              <p style={{ color: "#757575", fontSize: "1rem", margin: "0.5rem 0" }}>
                {account.description}
              </p>
            </div>
          </Stack>

          <div style={{ padding: "1rem 0", display: "grid", gap: "1rem", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))" }}>
  {[
    { label: "Age", value: account.ageProfile, icon: "👤" },
    { label: "Family", value: account.familySituation, icon: "🏠" },
    { label: "Networth", value: account.networth, icon: "💰", progress: (account.networthNumber / 10000000) * 100 },
    // { label: "Assets", value: account.assets, icon: "📈", progress: (account.assets / account.networth) * 100 },
    // { label: "Liabilities", value: account.liabilities, icon: "📉", progress: (account.liabilities / account.networth) * 100 },
    { label: "Income", value: account.income, icon: "💵", progress: (account.incomeNumber / 500000) * 100 },
    // { label: "Expenses", value: account.expenses, icon: "💳", progress: (account.expenses / account.income) * 100 },
    { label: "Portfolio", value: account.portfolioDetails, icon: "📊" },
  ].map(({ label, value, icon, progress }) => (
    <div
      key={label}
      style={{
        padding: "0.5rem",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "0.5rem",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
        <span style={{ fontSize: "1.5rem" }}>{icon}</span>
        <strong>{label}:</strong>
        <div style={{ fontSize: "1rem", color: "#555" }}>{value}</div>
      </div>
      
      {progress !== undefined && (
        <div style={{ width: "100%", background: "#eee", borderRadius: "4px", overflow: "hidden" }}>
          <div
            style={{
              width: `${progress}%`,
              height: "8px",
              background: "#4caf50",
              transition: "width 0.3s ease",
            }}
          ></div>
        </div>
      )}
    </div>
  ))}
</div>
        </div>
      ))}
    </div>
    <div style={{position:"sticky",bottom:0,background:"black",width:"100%"}}>
      <div style={{padding:"1rem",textAlign:"center"}}>
        <Button variant="contained" onClick={()=>{handleModal(false)}}>Continue with Demo</Button>
      </div>
    </div>
    </div>
    </Modal>}
    </>
  );
};

// export function DemoProfileLogo(){
//   const {stateMyFinancials} = useAppInfo()
//   const {demoProfileSelected} = stateMyFinancials
//   return(
//     <>
//     <Avatar src={demoProfileSelected?.img}/>
//     </>
//   )
// }



export function DemoProfile({demoProfile="Ambitious Alex"}){
  const {stateMyFinancials,dispatchMyFinancials} = useAppInfo();
  // const {demoProfile} = stateMyFinancials
  const demoProfileSelected = demoAccounts.filter(account => account.name === demoProfile)[0]
  return(
    <>
     <Avatar
            src={demoProfileSelected?.img}
            style={{
              cursor: "pointer",
              height: "30px",
              width: "30px",
              backgroundColor: "#gray",
              color: "black",
              fontSize: "0.8rem",
            }}
          >
            {demoProfileSelected && demoProfileSelected?.avatar}
          </Avatar>
    </>
  )
}

export const demoAccounts = [
    {
      name: "Ambitious Alex",
      description: "A driven young professional keen on turning high earnings into lasting financial success.",
      avatar: "A",
      img:firebaseMedia?.demoprofile_alex,
      ageProfile: "25-30 years",
      familySituation: "Single",
      assets: "Savings: $5K, 401K: $10K",
      liabilities: "Student Loan: $25K, Credit Card: $5K",
      income: "$50K - $100K Annually",
      expenses: "Rent: $1,500, Living: $2,000, Savings: $1,000",
      portfolioDetails: "Stocks: 60%,Crypto:10%, Cash: 30%",
      networth: "$10K - $50k",
      networthNumber:"30000",
      incomeNumber:"100000"
    },
    {
      name: "Thrive Together Tim & Tara",
      description: "A power couple prioritizing family stability while growing their wealth.",
      avatar: "T",
      img:firebaseMedia?.demoprofile_timtara,
      ageProfile: "40-50 years",
      familySituation: "Married with kids",
      assets: "Home: $600K, Savings: $90K, Joint Investments: $200K",
      liabilities: "Mortgage: $350K, Car Loan: $20K",
      income: "$200K - $400K Annually (Joint)",
      expenses: "Mortgage: $2,800, Living: $3,200, Kids' Education: $1,700",
      portfolioDetails: "Stocks: 20%, ETF:50%, Bonds: 20%, Cash: 10%",
      networth: "$500K - $800K",
      networthNumber:"600000",
      incomeNumber:"300000",
    },
    
   {
        name: "Visionary Vera",
        description: "A forward-thinker navigating family priorities while building wealth for the next generation.",
        avatar: "V",
        img:firebaseMedia?.demoprofile_vera,
        ageProfile: "50+ years",
        familySituation: "Married with kids",
        assets: "Savings: $400K, Home: $900K, 401K: $800K, Trust Fund: $600K",
        liabilities: "Mortgage: $300K, No Other Debt",
        income: "$500K - $1M Annually",
        expenses: "Mortgage: $3,000, Living: $4,000, Retirement Planning: $2,500",
        portfolioDetails: "Stocks: 30%,ETF:40%, Bonds: 10%, Real Estate: 10%, Crypto: 5%, Cash: 5%",
        networth: "$7M - $10M",
        networthNumber:"7000000",
        incomeNumber:"600000"
      }
    ]  


const moreDemoAccounts = [
      {
      name: "Hustler Harper",
      description: "Focused on saving and investing smartly to achieve major life goals like purchasing a home.",
      avatar: "H",
      ageProfile: "30-40 years",
      img:firebaseMedia?.demoprofile_harper,
      familySituation: "Single",
      assets: "Savings: $25K, Stock Portfolio: $40K, Home Fund: $60K",
      liabilities: "Student Loan: $30K",
      income: "$150K - $200K Annually",
      expenses: "Rent: $1,800, Living: $2,200, Savings: $1,200",
      portfolioDetails: "Stocks: 40%, ETF: 30%, Crypto:10%, Cash: 20%",
      networth: "$100K - $200k",
      networthNumber:"150000",
      incomeNumber:"200000"
    },
     // {
    //   name: "Go-Getter Grace",
    //   description: "Balancing career growth and thoughtful financial planning for future stability.",
    //   avatar: "G",
    //   ageProfile: "30-40 years",
    //   familySituation: "Single",
    //   assets: "Savings: $18K, 401K: $35K, Investment Account: $25K",
    //   liabilities: "Student Loan: $20K, Personal Loan: $10K",
    //   income: "$95K Annually",
    //   expenses: "Rent: $1,600, Living: $1,900, Savings: $1,300",
    //   portfolioDetails: "Stocks: 40%, Bonds: 30%, Cash: 30%",
    //   networth: "$48K"
    // },
    // {
    //   name: "Prosperous Priya",
    //   description: "A well-rounded professional juggling career, family, and future financial aspirations.",
    //   avatar: "P",
    //   ageProfile: "35-40 years",
    //   familySituation: "Married with kids",
    //   assets: "Home: $500K, Savings: $80K, 401K: $150K",
    //   liabilities: "Mortgage: $300K, Car Loan: $15K",
    //   income: "$250K Annually (Joint)",
    //   expenses: "Mortgage: $2,500, Living: $3,000, Kids' Education: $1,500",
    //   portfolioDetails: "Stocks: 70%, Bonds: 20%, Cash: 10%",
    //   networth: "$415K"
    // },
    {
      name: "Grounded Gabriel",
      description: "Thoughtful and steady, committed to providing for family needs while preparing for the future.",
      avatar: "G",
      ageProfile: "40-50 years",
      img:firebaseMedia?.demoprofile_gabriel,
      familySituation: "Married with kids",
      assets: "Home: $550K, Savings: $100K, 401K: $180K",
      liabilities: "Mortgage: $320K, Car Loan: $18K",
      income: "$200-$300K Annually",
      expenses: "Mortgage: $2,700, Living: $3,100, Kids' Education: $1,600",
      portfolioDetails: "Stocks: 10%, ETF: 80%, Cash: 10%",
      networth: "$400K - $700K",
      networthNumber:"500000",
      incomeNumber:"260000"

    },
    // {
    //   name: "Legacy Linda",
    //   description: "Focused on creating a secure future for her family while planning generational wealth.",
    //   avatar: "L",
    //   ageProfile: "50-55 years",
    //   familySituation: "Married with kids",
    //   assets: "Savings: $200K, Home: $750K, 401K: $500K, Other Investments: $200K",
    //   liabilities: "Mortgage: $200K, No Other Debt",
    //   income: "$300K Annually",
    //   expenses: "Mortgage: $2,500, Living: $3,500, Retirement Planning: $1,500",
    //   portfolioDetails: "Stocks: 50%, Bonds: 40%, Real Estate: 10%",
    //   networth: "$1.45M" 
    // },

    {
      name: "Steady Stephen",
      description: "Confident and strategic in managing financial assets to meet lifelong goals.",
      avatar: "S",
      ageProfile: "50-60 years",
      img:firebaseMedia?.demoprofile_stephan,
      familySituation: "Married",
      assets: "Savings: $300K, Home: $800K, 401K: $700K, Real Estate: $500K",
      liabilities: "Mortgage: $250K, No Other Debt",
      income: "$350K - $600K Annually",
      expenses: "Mortgage: $2,800, Living: $3,700, Retirement Planning: $2,000",
      portfolioDetails: "Stocks: 35%,ETF:30%, Bonds: 20%, Real Estate: 10%, Cash: 5%",
      networth: "$2.5M - $5M",
      networthNumber:"3500000",
      incomeNumber:"450000"
    },
  
  ]