import React, { useState } from 'react'
import TabLine from 'components/Tabs/TabLine';
import { Avatar, Button } from '@mui/material';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import TuneIcon from '@mui/icons-material/Tune';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';

export default function Clients() {

    const dataDummy = [
        { id: 1, name: 'John Doe',accountType:"Individual",engagementType:"All",upcomingEvents:3,manage:""},
        { id: 2, name: 'Jane Smith',accountType:"Individual" ,engagementType:"Budegting and Planning",upcomingEvents:1,manage:""},
        { id: 3, name: 'Mike Johnson',accountType:"Family" ,engagementType:"Portfolio Management",upcomingEvents:2,manage:""},
        { id: 4, name: 'Sarah Williams',accountType:"Corporate" ,engagementType:"Retirement Planning",upcomingEvents:4,manage:""},
      ];
    const [tabIndex, setTabIndex] = useState(0);
    const tabList = ["All","Top Clients","New Clients"];
    const columns = dataDummy.length > 0 ? Object.keys(dataDummy[0]) : [];
    const [rowHover,setRowHover] = useState(-1)
  return (
    <div style={{width:"100%"}}>
        {/* <TabLine tabList={tabList} tabIndex={tabIndex} handleTabChange={(index)=>setTabIndex(index)}/> */}
        <div style={{display:"flex",gap:"1rem",alignItems:"center",padding:"1rem"}}>
        <h3 style={{flex:1}}>Clients</h3>
        <div style={{display:"flex",gap:"1rem",alignItems:"center",}}>
        <Button startIcon={<TuneIcon/>} variant="highlight" sx={{width:"max-content"}}>Filter</Button>
        <Button startIcon={<AutoAwesomeIcon/>}  variant="highlight" sx={{width:"max-content"}}>Insights</Button>
       
        </div>
   
        </div>
        
        <table style={{tableLayout: 'fixed',width:"100%"}}>
        <thead>
          <tr>
           <th>id</th>
           <th>Name</th>
           <th>Account Type</th>
           <th>Engagement Type</th>
            <th>Upcoming Events</th>
            <th>Report</th>
            <th>Authorization/Share</th>
            <th>Edit/Settings</th>
          </tr>
        </thead>
        <tbody>
          {dataDummy.map((person, index) => (
            <tr 
            onPointerEnter={()=>setRowHover(index)}
            onPointerLeave={()=>setRowHover(-1)}
            style={{position:"relative"}} key={person.id}>
           <td>{person.id}</td>
           <td>{person.name}</td>
           <td>{person.accountType}</td>
           <td>{person.engagementType} </td>
           <td>
            <div style={{display:"flex",gap:"1rem"}}>
            {person.upcomingEvents} <EventIcon sx={{color:"gray"}}/>
            </div>
            </td>
            {/* <td >Manage</td> */}
            <td ><SummarizeIcon style={{color:"gray"}}/></td>
            <td ><Avatar sx={{ width: 24, height: 24 }}/>
            
            </td>
           <td>
            <EditIcon style={{color:"gray"}}/>
           {rowHover===index && <div style={{position:"absolute",top: "50%",right: "3px",
      transform: "translateY(-50%)",background:"#f7f7f7",borderRadius:"10px",padding:"0.05rem"
                ,zIndex:1,color:"black",fontSize:"0.9rem",cursor:"pointer"}}>
                View Client
                </div>}
           </td>
            </tr>
          ))}
        
        </tbody>
      </table>
    </div>
  )
}
