import React from 'react'
import styles from "./homeMobile.module.css"
import Logo from '../logo/Logo'
import ContactIcon from './ContactIcon'

export default function HomeMobile() {

  function handleSignUp() {
    const emailBody = `Hello there,%0D%0A%0D%0AName:%0D%0A%0D%0AEmail:`;
    window.location.href = `mailto:yash@autonomyfinance.com?subject=Beta Program Sign In Request&body=${emailBody}`;
  }

  return (
    <div className={styles.container}>
        <div className={styles.header}>
        <div style={{display:"flex",gap:"1rem",alignItems:"center",justifyContent:"center"}}>
        <Logo/>
        <h1 style={{color:"white"}}>Autonomy</h1></div>
        <h2 style={{ textAlign: "center",color:"white"}}>More Signal. Less Noise.</h2>
        </div>

        <div className={styles.body}>
    
   
    <h3 style={{fontWeight:"bold",textAlign:"left",color:"black"}}>Hey there, welcome to Autonomy.</h3>
    <p>We're currently optimizing our experience for mobile and tablet users. In the meantime, we invite you to view our content on a desktop or larger screen.</p>
    <p>We're working hard to bring you a seamless mobile experience very soon. Stay tuned!</p>
    <p>Thank you for your understanding and patience. We appreciate it!</p>
    <p style={{fontWeight:"bold"}}>If you're curious about our offerings or interested in joining our Beta program, we'd love to connect with you!</p>
  <button className='btn' style={{marginTop:"1rem"}} onClick={handleSignUp}>Sign up for Beta</button>

     <video autoPlay muted loop controls style={{width:"100%",height:"50%",marginTop:"1rem",borderRadius:"5px",border:"none",padding:0}}>
        <source src="\TakeStockWebsite.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
  
  <p style={{fontWeight:"bold",marginTop:"2rem"}}>Feel free to contact us via email, phone, or on our social media channels.</p>
<ContactIcon/>

</div>


    </div>
  )
}
