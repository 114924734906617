import React, { useState} from "react";
import {Button,} from "@mui/material";
import { Delete} from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DescriptionIcon from "@mui/icons-material/Description";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SelectBox from "components/Inputs/SelectBox";
import TextInput from "components/Inputs/TextInput";
import LoadingSkeletonPage from "components/Loading/LoadingSkeletonPage";
import MediaDisplayModal from "./MediaDisplayModal";
import MovieIcon from '@mui/icons-material/Movie';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useLocation } from "react-router-dom";
import { useAppInfo } from "AppState";
import { fetchAIVision } from "chatGPT/VisionRecognition";
import Modal from "components/Modal/Modal";
import ReactMarkdown from 'react-markdown';
import { identityCategories } from "financialVault/Categories/FinancialVaultIdentity";
export default function FinancialVaultTableUpload({showFolder=false,showAISummary,showSubFolder, mediaItems,handleChange,handleDelete}) {
    const {stateMyFinancials} = useAppInfo()
    const {vaultCategories} = stateMyFinancials
    const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = useLocation()
    const subPage = location.pathname.split('/')[2];
    const [aiModal,setAIModal] = useState("")
    const [aiSummaryMessage,setAIMessage] = useState("")


    const handleModal = (index) => {
        setSelectedMediaIndex(index);
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
        setSelectedMediaIndex(null);
      };

    async function handleAI (item) {
     setAIModal(true)
     setAIMessage({img:item?.url})
     const response = await fetchAIVision({image_url:item?.url});
     const message = response?.message?.content || ""
     handleChange("aiSummary",message,item?.id)
     setAIMessage({img:item?.url,message:message})
   
    }
    
    return (
    <>
    <div style={{width:"100%",overflow:"auto"}}>
  <table style={{tableLayout:"fixed",width:"100%"}}>
    <thead>
      <tr>
      <th style={{ width: "50px", maxWidth: "50px" }}>Doc</th>
        {showFolder && <th style={{ width: "auto" }}>Folder</th>}
        <th style={{ width: "auto" }}>Name</th>
        <th style={{ width: "auto" }}>Date</th>
        {showAISummary && <th>AI Summary</th>}
        <th style={{ textAlign: "right", width: "auto" }}></th>
      </tr>
      </thead>
      <tbody>
      {mediaItems.length > 0 &&
      mediaItems.sort((a, b) => new Date(b.date) - new Date(a.date)).map((item, index) => 
      (<tr style={{position:"relative"}} key={index}>
        
        <td onClick={() => handleModal(index)} 
        style={{width: "50px",maxWidth:"50px",maxHeight:"50px",cursor:"pointer"}}>

        {item?.progress < 100  && (
                      <div
                        style={{ backdropFilter: 'blur(4px)'
                            ,background:"rgba(0, 0, 0, 0.05)",zIndex:1000,position:"absolute",top:0,left:0,width:"100%",height:"100%"}}
                      >
                        <LoadingSkeletonPage/>
                      </div>
                    )}
        <div style={{width:"25px",height:"25px",position:"relative"}}>
        {item.fileType.includes("image") && (
          <InsertPhotoIcon
          style={{ fontSize: "25px", cursor: "pointer",color:"var(--selectBlue)" }}
          />
            // <img
            //   src={item.url}
            //   alt={item.name}
            //   style={{ width: "100%", height: "100%", objectFit: "contain" }}
            //   onClick={() => handleModal(index)}
            // />
          )}
          {item.fileType.includes("pdf") && (
            <PictureAsPdfIcon
              style={{ fontSize: "25px", cursor: "pointer",color:"red"}}
              onClick={() => handleModal(index)}
            />
          )}
          {item.fileType.includes("doc") && (
            <DescriptionIcon
              style={{ fontSize: "25px", cursor: "pointer" }}
              onClick={() => handleModal(index)}
            />
          )}
          {item.fileType.includes("csv") && <ListAltIcon />}
          {item.fileType.includes("video") && (
            <MovieIcon
            style={{ fontSize: "25px", cursor: "pointer" }}
            />
            // <video
            //   src={item.url}
            //   style={{ width: "100%", height: "100%", objectFit: "contain" }}
            //   controls={false}
            //   onClick={() => handleModal(index)}
            // />
          )}
          </div>
        </td>
        {showFolder &&
        <td>
        <div style={{display:"flex",gap:"0.5rem",alignItems:"center"}}>
        <SelectBox
            // label="Category"
            options={vaultCategories}
            value={item.category}
            onChange={(e,v) => handleChange("category",v, item?.id)}
          />
          {item.category === "Identity & Personal Docs" && <SelectBox
          options={identityCategories}
          value={item.subCategory}
          onChange={(e,v) => handleChange("subCategory",v, item?.id)}
          />}
        </div>
       
        </td>}
        


        <td> <TextInput
            // label="Name"
            value={item.name}
            onChange={(v) => handleChange("name", v, item?.id)}
          /></td>
       
        <td>{formatDate(item?.date)}</td>
        {/* <td>coming soon!</td> */}
        {showAISummary && (
          
        
        <td onClick={() => {
  if (item?.aiSummary) {
    setAIModal(true);
    setAIMessage({img:item?.url,message:item?.aiSummary});
  } else {
    handleAI(item);
  }
}}>AI Summary</td>)}
        <td style={{ textAlign: "right" }}> <Button onClick={() => handleDelete(item)}>
            <Delete />
          </Button></td>
        </tr>
        ))}
  
      </tbody>
  </table>
  </div>
  {(selectedMediaIndex !== null && isModalOpen) && (
        <MediaDisplayModal 
        handleCloseModal={closeModal}
        modalOpen={isModalOpen} mediaItems={mediaItems} mediaSelectedIndex={selectedMediaIndex}/>
      )}

  {aiModal && <Modal onClose={() => setAIModal(null)} sx={{maxWidth:"80vw",minWidth:"500px"}} width='auto' height='auto'>
  <div style={{display:"flex",flexDirection:"column",gap:"1rem",padding:"1rem",maxHeight:"80vh",overflow:"auto"}}>

  <h3>AI Summary</h3>
  <div style={{display:"flex",gap:"1rem"}}>
  <img src={aiSummaryMessage?.img} style={{width:"500px",height:"300px",objectFit:"contain",borderRadius:"10px"}}/>
  {!aiSummaryMessage?.message && <p>Loading...</p>}
  <div>
  <ReactMarkdown>
   
    {aiSummaryMessage?.message && aiSummaryMessage?.message}
  </ReactMarkdown>
  </div>
  </div>
  </div>
    </Modal>}
  </>
    )
  }

  const formatDate = (date) => {
    // Parse the date if it's a string
    if (typeof date === "string") {
      date = new Date(date);
    }
  
    // Check if it's a valid date
    if (!(date instanceof Date) || isNaN(date)) {
      return date; // Return as-is if not a valid date
    }
  
    const now = new Date();
  
    // Helper function to add zero padding to single-digit numbers
    const padZero = (num) => (num < 10 ? `0${num}` : num);
  
    // Compare the given date with today
    // const isToday =
    //   now.toDateString() === date.toDateString();
  
    // // Compare the given date with yesterday
    // const yesterday = new Date(now);
    // yesterday.setDate(now.getDate() - 1);
    // const isYesterday =
    //   yesterday.toDateString() === date.toDateString();
  
    // if (isToday) {
    //   const hours = date.getHours();
    //   const minutes = padZero(date.getMinutes());
    //   const suffix = hours >= 12 ? "pm" : "am";
    //   const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour clock
    //   return `${formattedHours}:${minutes} ${suffix}`;
    // }
  
    // if (isYesterday) {
    //   return "Yesterday";
    // }
  
    // For dates before yesterday, return formatted as 'Mar 24, 2024'
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };
  