import React from "react";
import HomePageTemplate from "../../home/HomePageTemplate";

export default function Disclaimer() {
  return (
    <HomePageTemplate pageName="Disclaimer">
      <div style={{padding:"1rem",background:"white",borderRadius:"5px"}}>
      <h2>
        <strong>This is a Beta Version!</strong>
        <br />
        Please don't make any Investment or Trading Decisions based on the data
        provided - some of the data may be stale or inaccurate.
      </h2>
      <h2>
        <strong> No Investment Advice Provided</strong>
        <br />
        Trading leveraged products carries a high degree of risk and you could
        lose more than your initial deposit. Any opinions, chats, messages,
        news, research, analyses, prices, or other information contained on this
        Website are provided as general market information for educational and
        entertainment purposes only, and do not constitute investment advice.
        The Website should not be relied upon as a substitute for extensive
        independent market research before making your actual trading decisions.
        Opinions, market data, recommendations or any other content is subject
        to change at any time without notice. Autonomy a product of STOCKR
        SYSTEMS INC. will not accept liability for any loss or damage, including
        without limitation any loss of profit, which may arise directly or
        indirectly from use of or reliance on such information. We do not
        recommend the use of technical analysis as a sole means of trading
        decisions. We do not recommend making hurried trading decisions. You
        should always understand that PAST PERFORMANCE IS NOT NECESSARILY
        INDICATIVE OF FUTURE RESULTS.
      </h2>
      </div>
    </HomePageTemplate>
  );
}
