import React, { useEffect } from 'react'
import useMultiTickerInfo from 'data/ticker/useMultiTickerInfo'
import { useAppInfo } from 'AppState'

export default function usePortfolioTrendDetails() {
  const {statePortfolio,dispatchPortfolio,stateMyFinancials} = useAppInfo()  
  const {close,trendTable,marketInfoLoading}= useMultiTickerInfo(statePortfolio.tickerSelected)
 
    useEffect(()=>{
        if (close.length>0){
          dispatchPortfolio({
            close: close,
            trendTable: trendTable,
            marketInfoLoading: marketInfoLoading
          });          
      }
      },[close])


    useEffect(() => {
      console.log("PortfolioValue is being updated",close.length>0, statePortfolio.portfolioTable.length>0)
      if (!statePortfolio.portfolioLoaded){return}
      if (close.length>0 && statePortfolio.portfolioTable.length>0){
      const portfolioValueOverTime = getPortfolioValueOverTime(close, 
        statePortfolio.portfolioTable);
      dispatchPortfolio({"portfolioValue":portfolioValueOverTime})
    }
    else{
      dispatchPortfolio({"portfolioValue":[]})
    }
    }, [close,statePortfolio.portfolioTable,statePortfolio.portfolioLoaded]);
}


function getPortfolioValueOverTime(closePrices, investments) {
  const portfolioValues = [];

    closePrices.forEach((closePriceData) => {
    const currentDate = closePriceData.Date;

  
    let totalPortfolioValue = NaN;
    
    investments.forEach((investment) => {
      const ticker = investment.TICKER;
      const quantity = investment.quantity;
      const investmentDate = new Date(investment.date).toISOString().split('T')[0];

      if (new Date(investmentDate) <= new Date(currentDate)) {
        if (isNaN(totalPortfolioValue)) {
          totalPortfolioValue = 0;
        }
        totalPortfolioValue += quantity * closePriceData[ticker];
      }
    });

   
    portfolioValues.push({ Date: currentDate, "Portfolio": totalPortfolioValue });
  });

  return portfolioValues;
}
