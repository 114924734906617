import React, { useEffect, useRef, useState } from "react";
import { useAppInfo } from "AppState";
import ColorBar from "./ColorBar";
import styles from "./TableGen.module.css";
import TickerCardSparkline from "../Charts/TickerCardSparkline";
import {dfin} from "content/constants";
import BarSVG from "../Charts/BarSVG";
import PaginationButtons from "../PaginationButtons";
import RealTimePriceTable from "./RealTimePriceTable";
import { calculateGradientColor, calculateMinMax, containsLink,formatValue, resizeColumn, standardizedData, tableGrowthRate } from "./TableUtils";
import SortArrow from "./SortArrow";
import useHandleTickerChange from "analytics/ticker/hooks/useHandleTickerChange";
import { PopoverSettings } from "components/Template/MetricSettings";
import {PageMainContentTitle} from "components/Text/Title";
import { WatchListAddTableButton } from "watchlist/WatchListAddButton";
import { PeerAddButton } from "peerList/PeerAddButton";
import DetailTradeTickerButton from "analytics/ticker/DetailTradeTickerButton";
import LogoTicker from "components/LogoTicker";


export default function TableGen({
  data=[],
  tableStyle="table",
  sx = {},
  sxWrapper={},
  height="100%",
  color = "",
  colorThreshold,
  colorGradient = "minMax",
  colFirst = null,
  tableHeader = null,
  tableFilter = null,
  tableLayout = "auto",
  tableName = "table",
  hoverInfo = true,
  tickerDict,
  tickerPrice,
  pagination=true,
  logo,
  performanceCols=[],
  trend=false,
  addToWatchlist = false,
  addToPeers = false,
  realTimePrice=false,
  handleTableRow,
  tHead=true,
  showSelection=true,
  tableSettings={metricSettings:false},
  handleTableSettings={},
  addColumns=false,
  growthRate={show:false,type:"sub"},
}) {


  const {stateTicker} = useAppInfo();
  const handleTickerChange = useHandleTickerChange()
  
  const performanceColumns = [...performanceCols,dfin.performance1D,dfin.performance1M,dfin.performance3M,dfin.performance6M,dfin.performance1Y,"Profit/Loss","Profit/Loss (%)","Daily Change","Daily Change (%)"]
  
  const [dataTable, setDataTable] = useState([]);
  const totalItems = data.length
  const [currentPage,setCurrentPage] = useState(1)
  const itemsPerPage = 50
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [draggedItem, setDraggedItem] = useState(null);
 

  useEffect(() => {
    
    let tableData = standardizedData(data)
    if (pagination){
      tableData = tableData.slice(indexOfFirstItem,indexOfLastItem)
    }
    setDataTable(tableData);
  }, [data,currentPage]);


  const handleSort = (columnName) => {
    if (columnName === sortedColumn) {
      // If the same column is clicked again, toggle the sort direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If a different column is clicked, set it as the new sorted column and default to ascending
      setSortedColumn(columnName);
      setSortDirection("asc");
    }
  };

  useEffect(() => {
    let sortedData = standardizedData(data).sort((a, b) => {
      const valueA = a[sortedColumn];
      const valueB = b[sortedColumn];
      
      if (typeof valueA === "number" && typeof valueB === "number") {
        return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
      } 
      else if (typeof valueA === "string" && typeof valueB === "string") {
        return sortDirection === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } 
      else if (
        valueA instanceof Date &&
        valueB instanceof Date
      ) {
        return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
      } 
      else {
        // Handle other dataTable types as needed
        return;
      }
    });
    if (pagination){
      sortedData = sortedData.slice(indexOfFirstItem,indexOfLastItem)
    }

    setDataTable(sortedData);
  }, [sortedColumn, sortDirection,data]);

  const handleDragOver = (e, index) => {
    e.preventDefault();
  };

  const handleDragStart = (e, index, row) => {
    setDraggedItem(row);
    e.dataTransfer.setData("text/plain", index.toString());
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    // Get the dragged item's index from dataTransfer
    const draggedItemIndex = parseInt(e.dataTransfer.getData("text/plain"));
    // Clone the current data table
    const updatedData = [...dataTable];
    // Remove the dragged item from its original position
    const [draggedItemRow] = updatedData.splice(draggedItemIndex, 1);
    // Insert the dragged item at the new position
    updatedData.splice(index, 0, draggedItemRow);
    // Update the dataTable with the new order
    setDataTable(updatedData);
    setDraggedItem(null);
  };



  // Usage:
  const { minValue, maxValue } = calculateMinMax(dataTable);
  
  // Check if "Ticker" key exists in the data
  const tickerExists = dataTable[0] && dataTable[0].hasOwnProperty("Ticker");

  const tableRef = useRef(null); // Ref for the table element
  

  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);

  function handleRowMouseEnter(index) {
    setHoveredRowIndex(index);
  }

  function handleInfo(e, v) {
    handleTickerChange({ticker:v});
  }


  const [mouseColumnEnter,setMouseColumnEnter] = useState(false)
  const [showTableOverflow,setShowTableOverflow] = useState(false)

  function handleRow(row) {
    if (typeof handleTableRow === 'function') {
        handleTableRow(row);
    } 
  }

  

  return (
    <div 
    style={{display:"flex",flexDirection:"column",boxSizing:"border-box",width:"100%",height:height
    ,borderRadius:"5px",...sxWrapper}}>

      {(tableHeader || tableFilter) && 
      <TableHeader 
      tableFilter={tableFilter}
      tableHeader={tableHeader}
      tableName={tableName}
      tableSettings={tableSettings}
      handleTableSettings={handleTableSettings}
      color={color}
      minValue={minValue}
      maxValue={maxValue}
      />
      }

      <div
        onMouseEnter={()=>setShowTableOverflow(true)}
        onMouseLeave={()=>setShowTableOverflow(false)}
        style={{
          overflow: showTableOverflow?"auto":"hidden",
          flex:1,
          width: "100%",
        }}
      >
        {/* {dataTable.length===0 && <LoadingSkeletonTable length={data.length}/>} */}
        {dataTable.length > 0 && (
          <table
            id={"tableGen"}
            ref={tableRef}
            style={{ tableLayout: tableLayout,...sx }}
            className={styles[tableStyle]}
          >
            {tHead && <thead>
              <tr>
                {addToWatchlist && (
                  <th onMouseDown={(e) => resizeColumn(e, "Watchlist")}>
                    Add to Watchlist
                  </th>
                )}
                 {addToPeers && (
                  <th onMouseDown={(e) => resizeColumn(e, "Peers")}>
                    Add to Peers
                  </th>
                )}


                {tickerExists && (
                  <th 
                  onMouseDown={(e) => resizeColumn(e, "Ticker")}>
                    <div style={{ display: "flex", gap: "0.3rem" }}>
                      {"Ticker"}{" "}
                      <div onClick={() => handleSort("Ticker")}>
                        <SortArrow />
                      </div>
                    </div>
                  </th>
                )}
                {tickerDict && (
                  <th onMouseDown={(e) => resizeColumn(e, "Name")}>
                    <div style={{ display: "flex", gap: "0.3rem" }}>
                      {"Name"}{" "}
                      
                      <div onClick={() => handleSort("Name")}>
                        <SortArrow />
                      </div>
                    </div>
                  </th>
                )}
                {(tickerPrice) && (
                  <th onMouseDown={(e) => resizeColumn(e, "52W Trend")}>
                    <div style={{ display: "flex", gap: "0.3rem" }}>
                      {"52W Trend"}{" "}
                      {/* <div onClick={() => handleSort("Name")}>
                        <SortArrow />
                      </div> */}
                    </div>
                  </th>
                )}

                {trend && <th>Trend</th>}

                {!realTimePrice && Object.keys(dataTable[0]).map(
                  (key) =>
                    (!["Ticker",dfin.coLogo].includes(key)) && (
                      <th onMouseDown={(e) => resizeColumn(e, key)} key={key} 
                      onMouseEnter={()=>setMouseColumnEnter(true)}
                      >
                        <div style={{ display: "flex", gap: "0.3rem" }}>
                          {key ==="ISO 2"?"":key}{" "}
                          {key !=="ISO 2"&&
                          <div onClick={() => handleSort(key)}>
                            <SortArrow />
                          </div>}
                        </div>
                        <div 
                        onMouseDown={(e) => resizeColumn(e, key)}
                        onMouseEnter={()=>setMouseColumnEnter(true)} 
                        style={{
                        position:"absolute",right:0,top:0,
                        cursor:mouseColumnEnter&&"e-resize",
                        background:"trasparent",
                        width:"5px",height:"100%"}}></div>
                      </th>
                    )
                )}
                {realTimePrice && <th>NAME</th>}
                {realTimePrice && <>
                <th>Last</th>
                <th>Chg</th>
                <th>Chg %</th>
                </>}
              </tr>
            </thead>}
            <tbody>
              {/* Table rows */}
              {dataTable.map((row, index) => (
                <tr
                  style={{ position: "relative" }}
                  key={index}
                  className={
                    showSelection && row.Ticker === stateTicker.tickerSelected
                      ? styles.selectedRow // Apply the selected-row style
                      : row === draggedItem
                      ? styles.draggedRow // Apply the dragged-row style
                      : styles.tableRow // Default style
                  }
                  onClick={(e) => {
                    if (
                      tickerExists &&
                      !e.target.classList.contains("add-icon") &&
                      !e.target.classList.contains("check-icon")
                    ) {
                      handleInfo(e, row.Ticker)
                    }
                    handleRow(row)
                  }}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index, row)}
                  onDragOver={(e) => handleDragOver(e, index)}
                  onDrop={(e) => handleDrop(e, index)}
                  onMouseEnter={() => handleRowMouseEnter(index)}
                  onMouseLeave={() => handleRowMouseEnter(-1)}
                >
                  {addToWatchlist && (
                    <td style={{width:"50px"}}>
                      <WatchListAddTableButton ticker={row.Ticker}/>
                    </td>
                  )}
                  {addToPeers && (
                    <td>
                       <PeerAddButton ticker={row.Ticker}/>
                    </td>
                  )}

                {tickerExists && (
                    <td>
                      <div style={{display:"flex",gap:"1rem",alignItems:"center",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>
                        {logo && <LogoTicker tickerSel={row.Ticker}/>}
                        {row.Ticker}
                        {((hoverInfo && hoveredRowIndex === index))
                          // row.Ticker === stateStock.companySelected) 
                          && (
                            <DetailTradeTickerButton ticker={row.Ticker}/>
                        )}
                      </div>
                    </td>
                  )}
                  {tickerDict && <td style={{whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>{tickerDict[row.Ticker]}</td>}
                  {realTimePrice && <td>{row.NAME}</td>}
                  {realTimePrice && <RealTimePriceTable tickerSel={row["CODE"]}/>}
                  {tickerPrice && (<td>
                            <TickerCardSparkline
                              title={false}
                              close={tickerPrice}
                              tickerDict={{ [row.Ticker]: tickerDict ? tickerDict[row.Ticker] : row.coName }}
                            />
                            </td>
                          )}
                  {colFirst !== null && <td>{row[colFirst]}</td>}
                  {(trend) && 
                  <td style={{padding:"0 1rem"}}><BarSVG data={Object.values(row).filter(value => typeof value === 'number')} 
                  width="40" height={"40"}/></td>}


                  {!realTimePrice && Object.keys(row).map(
                    (key,index) =>
                      key !== "Ticker" &&
                      key !== dfin.coLogo &&
                      key !== colFirst &&
                      (
                        <td
                          key={key}
                          style={{
                            background:
                              typeof row[key] === "number" &&
                              !isNaN(row[key]) &&
                              color === "redGreen"
                                ? calculateGradientColor(
                                    row[key],
                                    minValue,
                                    maxValue,
                                    colorGradient,
                                    colorThreshold
                                  )
                                : {},
                            color:
                              typeof row[key] === "number" &&
                              performanceColumns.includes(key) &&
                              row[key] >= 0
                                ? "green"
                                : typeof row[key] === "number" &&
                                performanceColumns.includes(key) &&
                                  row[key] < 0
                                ? "red"
                                : undefined,
                            
                          }}
                        >
                          {containsLink(row[key]) && <a style={{color:"blue",overflow:"hidden",whiteSpace:"nowrap",textOverflow:"ellipsis"}} target="blank" href={row[key]}>{row[key]}</a>}
                          {key === "ISO 2" && 
                          <span style={{height:"30px",width:"60px",border:"1px solid #ddd"}} class={`fi fi-${row[key].toLowerCase()} fib`}>
                            </span>}
                          
                          {(!containsLink(row[key]) && key !== "ISO 2" && growthRate?.type !=="main") && formatValue(row[key])}
                          
                          {growthRate?.show && tableGrowthRate({row,key,index,type:growthRate?.type})}
                          
                        </td>
                      )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
            
      </div>
      
      {pagination &&  
      <PaginationButtons 
      totalItems={totalItems} 
      currentPage={currentPage} handlePage={(v)=>setCurrentPage(v)}
      itemsDisplaying={dataTable.length} 
      itemsPerPage={itemsPerPage}/> 
      }
    </div>
  );
}

function TableHeader({tableHeader,tableFilter,tableName,minValue,maxValue,color,
  tableSettings={},handleTableSettings}){
  return (
    <div
    style={{
      display: "flex",
      width: "100%",
      padding:"0.5rem 0.25rem",
      alignItems: "center",
      boxSizing: "border-box",
      gap: "1rem",
    }}
  >
    <div>
      {tableHeader !== null && (
        <PageMainContentTitle style={{ fontWeight: "bold" }}>{tableHeader}</PageMainContentTitle>
      )}
    </div>
    <div style={{ flex: "1" }}>{tableFilter}</div>

    {color === "redGreen" && (
      <ColorBar maxValue={maxValue} minValue={minValue} />
    )}
    <PopoverSettings
    title="Table Settings"
    metricSettings={tableSettings?.metricSettings}
    excelDownload
    excelDownloadType="html"
    handleMetricSettings={()=>handleTableSettings({metricSettings:true})}
    excelWbName={tableName}
    />
  </div>
  )
}